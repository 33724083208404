import React, { createContext, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { convertClientDesign, convertTripDetails } from '../core/services/converts';
import StorageService from '../core/services/storage';
import { getClientDesign, getTripDetails } from '../core/services/trip';
import { WELCOME_SCREEN_DURATION } from '../core/utils/common';
import { Routes } from '../core/utils/endpoints';
import { InsertAllTripItemsIDsIntoMap } from '../core/utils/feed';
import Mixpanel, { MP_Events, MP_Props } from '../core/services/mixpanel';
import ParamsService from '../core/services/params';
import { useLayout } from './LayoutProvider';
import { ThemeProvider } from 'styled-components';

export interface ITripDetailsProvider {
  tripDetails: ITripDetailsState;
  setTripDetailsWrapper: (updatedTripDetails: any, convertedAlready: boolean) => void;
  updateTripDetails: () => any;
  navigatePageWithWelcomeScreen: (destination: Routes, params?: string) => void;
}
export interface ITripDetailsState extends ITripDetails_Converted {
  itemsIDs: number[];
}
export const BRIDGIFY_DEFAULT_JSON: IClientDesign = {
  Logo: null,
  name: 'bridgify',
  MERCHANT_FLOW: false,
  TOKENLESS_ONBOARDING: false,
  HOTEL_SELECTION: false,
  CITY_SELECTION: false,
  DATE_SELECTION: false,
  PrimaryColor: {
    bgColor: '#631D76',
    textColor: 'white',
  },
  SecondaryColor: {
    bgColor: '#EDAE5F',
    textColor: 'black',
  },
  TertiaryColor: {
    bgColor: '#000235',
    textColor: 'white',
  },
  Images: {
    Image1: {
      text: 'We are building the perfect itinerary for you...',
      imgURL: 'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/man.jpg',
    },
    Image2: {
      text: 'Our smart algorithms are scanning the web for you...',
      imgURL:
        ' https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/boys.jpg',
    },
    Image3: {
      text: ' We are calculating the best trip itinerary based on your needs & interests.',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/falcon.jpg',
    },
    Image4: {
      text: "We don't save any private information... Only the final itinerary is saved.",
      imgURL:
        ' https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/light.jpg',
    },
    Image5: {
      text: 'The itinerary is highly custimizable! You can explore & book unique activities for your trip.',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/groynes.jpg',
    },
    Image6: {
      text: 'Next up is your itinerary!',
      imgURL:
        'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/clients/bridgify/black.jpg',
    },
  },
};
const defaultValue: ITripDetailsProvider = {
  setTripDetailsWrapper: () => {},
  tripDetails: {
    _id: 0,
    tripAccommodation: {
      accommodation_address: '',
      accommodation_name: '',
      city: '',
      id: 0,
      number_of_days: 0,
      start_date: null,
      finish_date: null,
      state: '',
    },
    favorites: [],
    tripPreferences: { intensity: 0, interests: [] },
    tripDays: [],
    itemsIDs: [],
    ClientDesign: BRIDGIFY_DEFAULT_JSON,
  },
  updateTripDetails: () => {},
  navigatePageWithWelcomeScreen: () => {},
};

const TripDetailsContext = createContext<ITripDetailsProvider>(defaultValue);

export const useTripDetails = (): ITripDetailsProvider => useContext(TripDetailsContext);

export function TripDetailsProvider({ children }) {
  const [tripDetails, setTripDetails] = useState<ITripDetailsState>({
    _id: 0,
    tripAccommodation: {
      accommodation_address: '',
      accommodation_name: '',
      city: '',
      id: 0,
      number_of_days: 0,
      start_date: null,
      finish_date: null,
      state: '',
    },
    tripPreferences: { intensity: 0, interests: [] },
    tripDays: [],
    favorites: [],
    itemsIDs: [],
    ClientDesign: BRIDGIFY_DEFAULT_JSON,
  });

  const { ClientDesign } = tripDetails;
  //Set client throuth the URL address or if testing through the tested customer
  const { addToast } = useToasts();
  const history = useHistory();
  const { isDesktop } = useLayout();

  const { PrimaryColor, SecondaryColor, TertiaryColor } = ClientDesign;
  const theme: ITheme = {
    primary: PrimaryColor,
    secondary: SecondaryColor,
    tertiary: TertiaryColor,
  };

  // async function updateClientDesign(): Promise<IClientDesign> {
  //   try {
  //     const clientName = ClientDesign.name;
  //     if (clientName) {
  //       const clientDesignResponse: IClientDesignResponse = await getClientDesign(clientName, true);
  //       const convertedClientDesign = convertClientDesign(clientDesignResponse);
  //       setClientDesign(convertedClientDesign);
  //       StorageService.local.save.clientDesign(convertedClientDesign);
  //       return convertedClientDesign;
  //     } else {
  //       return clientDesign;
  //     }
  //   } catch (error) {
  //     return clientDesign;
  //   }
  // }

  function navigatePageWithWelcomeScreen(destination: Routes, params: string = ''): void {
    const pathname = history.location.pathname;
    if (pathname === Routes.default || pathname === Routes.onBoarding) {
      setTimeout(() => {
        history.push(`${destination}${params}`);
      }, WELCOME_SCREEN_DURATION);
    }
  }

  /**
   * A wrapper function for setTripDetails.
   * @param updatedTripDetails
   * @param convertedAlready - indicates if there's a need for data convertion for the setState
   */
  async function setTripDetailsWrapper(
    updatedTripDetails,
    convertedAlready = false,
  ): Promise<ITripDetailsState> {
    const updatedAndConvertedTripDetails = convertedAlready
      ? updatedTripDetails
      : convertTripDetails(updatedTripDetails);

    //Update itemsID on every trip update
    const itemsIDs = Array.from(InsertAllTripItemsIDsIntoMap(updatedAndConvertedTripDetails));

    const ClientDesign = await fetchClientDesign();
    const tripDetailsWithItemsIS: ITripDetailsState = {
      ...updatedAndConvertedTripDetails,
      itemsIDs,
      ClientDesign,
    };
    setTripDetails(tripDetailsWithItemsIS);
    StorageService.session.save.tripDetails(tripDetailsWithItemsIS);
    return tripDetailsWithItemsIS;
  }

  async function updateTripDetails() {
    try {
      const tripDetails = await getTripDetails();
      setTripDetailsWrapper(tripDetails);
    } catch (error) {
      const errorCode: string = ('' + error).includes('400') ? '400' : '401';
      throw new Error(errorCode);
    }
  }

  function initToken(): string | null {
    //Change token in case of a new one
    let leastUpdatedToken: string | null = StorageService.local.get.token();
    const paramToken: string | null = ParamsService.getToken();
    if (paramToken && paramToken !== leastUpdatedToken) {
      StorageService.session.clear();
      leastUpdatedToken = paramToken;
      StorageService.local.save.token(leastUpdatedToken);
    }
    return leastUpdatedToken;
  }

  /**
   * This function has two jobs:
   * 1. Fetch the trip details with the given token
   * 2. Sets the client design from the tripDetails response or fetch it independently
   */
  async function fetchTripDetails(): Promise<ITripDetailsState> {
    const cachedTripDetails: ITripDetails_Converted | null =
      StorageService.session.get.tripDetails();
    if (cachedTripDetails) {
      // CASE 1: YES- Valid Token, YES- Cached Trip - Same session, no need to track analytics\
      const tripDetailsState = setTripDetailsWrapper(cachedTripDetails, true);
      return tripDetailsState;
    } else {
      const tripDetails = await getTripDetails();
      const tripDetailsState: ITripDetailsState = await setTripDetailsWrapper(tripDetails, false);
      return tripDetailsState;
    }
  }

  /**
   * This function takes the clientName from the URL parameters
   * and fetch it from the server
   * if fails--> sets the BRIDGIFY_DEFAULT_JSON as the default design
   */
  async function fetchClientDesign(): Promise<IClientDesign> {
    const cachedClientDesign: IClientDesign | null = StorageService.local.get.clientDesign();
    const paramClientName: string | null = ParamsService.getClient();

    //If there's already a cached client design, and there's no need to prioritize a param client
    if (cachedClientDesign && (!paramClientName || paramClientName === cachedClientDesign?.name)) {
      setTripDetails((prevState: ITripDetailsState) => ({
        ...prevState,
        ClientDesign: cachedClientDesign,
      }));
      return cachedClientDesign;
      //If there's a specific client param to fetch it's design
    } else if (paramClientName && paramClientName !== cachedClientDesign?.name) {
      try {
        const clientDesignResponse: IClientDesignResponse = await getClientDesign(
          paramClientName,
          true,
        );
        const convertedClientDesign = convertClientDesign(clientDesignResponse);
        setTripDetails((prevState: ITripDetailsState) => ({
          ...prevState,
          ClientDesign: convertedClientDesign,
        }));
        StorageService.local.save.clientDesign(convertedClientDesign);
        return convertedClientDesign;
      } catch {}
    }
    return BRIDGIFY_DEFAULT_JSON;
  }

  /**Clears the cookies if there's a mode=reuseable in the URL params */
  function cookiesInit(): void {
    if (ParamsService.getMode() === 'reuseable') {
      StorageService.session.clear();
      StorageService.local.clear();
    }
  }

  enum InitTripCases {
    ToFeed = 'To Feed', // 'trip found, redirection to the Feed'
    ToOnboarding = 'To Onboarding', // 'No trip, redirect to Onboarding flow'
    NoTokenError = 'No Token Error', //'User token is Missing, not a tokenless flow'
  }
  useEffect(() => {
    /**
     * Initialization of the trip details that includes:
     * 1. Trip details - itinerary details, favorites (Etc.)
     * 2. Client Design - client's theme + configuration (tokenless?, Etc.)
     *
     * The steps are:
     *    1. check for Existing trip Details - cache or stored in DB (including clientDesign)
     *      if yes--> load it
     *      else--> redirect to tokenless flow or throw Error
     */
    async function initTripDetails() {
      cookiesInit();
      try {
        //if user enters the link with a token-> don't trigger the dialog
        if (ParamsService.getToken()) StorageService.local.save.validatedEmail();

        const leastUpdatedToken = initToken();
        //If there's a token: fetch tripDetails
        //Else --> redirect to tokenless flow or throw Error
        if (leastUpdatedToken) {
          try {
            const tripDetails = await fetchTripDetails();
            const { ClientDesign } = tripDetails;
            //Check whether the trip-details response's 'design' field is empty
            // or there's a specific client parameter that should be noticed
            if (!ClientDesign || ParamsService.getClient() !== ClientDesign.name) {
              await fetchClientDesign();
            } else {
              StorageService.local.save.clientDesign(ClientDesign);
            }
          } catch (error: any) {
            if (error.message.includes('400')) {
              throw new Error(InitTripCases.ToOnboarding);
            } else {
              throw new Error(InitTripCases.NoTokenError);
            }
          }
        } else {
          const clientDesign = await fetchClientDesign();
          //If the client is tokenless-> go onboarding, else-> 404
          throw new Error(
            clientDesign.TOKENLESS_ONBOARDING
              ? InitTripCases.ToOnboarding
              : InitTripCases.NoTokenError,
          );
        }

        //Successfully loaded trip! Redirect to Feed
        throw new Error(InitTripCases.ToFeed);

        //Try get the trip details, if there's no trip, go to onboarding
      } catch (error: any) {
        const clientDesign: IClientDesign | null = await fetchClientDesign();
        const paramRedirectSrc: string | null = ParamsService.getRedirectionSource();
        if (paramRedirectSrc) StorageService.local.save.redirectionSource(paramRedirectSrc);
        switch (error.message) {
          case InitTripCases.ToFeed:
            Mixpanel.initAndIdentify();
            Mixpanel.register({
              [MP_Props.Desktop]: isDesktop,
              [MP_Props.client]: clientDesign?.name || 'bridgify',
              [MP_Props.tokenless]: clientDesign?.TOKENLESS_ONBOARDING === true,
              [MP_Props.redirected]:
                paramRedirectSrc || StorageService.local.get.redirectionSource() || undefined,
            });
            Mixpanel.track(MP_Events.AppOpen, {
              [MP_Props.tripCreated]: true,
              [MP_Props.firstTime]: false,
            });
            navigatePageWithWelcomeScreen(Routes.feed);
            break;
          case InitTripCases.ToOnboarding:
            Mixpanel.initAndIdentify();
            Mixpanel.register({
              [MP_Props.Desktop]: isDesktop,
              [MP_Props.client]: clientDesign?.name || 'bridgify',
              [MP_Props.tokenless]: clientDesign?.TOKENLESS_ONBOARDING === true,
              [MP_Props.redirected]:
                paramRedirectSrc || StorageService.local.get.redirectionSource() || undefined,
            });
            Mixpanel.track(MP_Events.AppOpen, {
              [MP_Props.tripCreated]: false,
              [MP_Props.firstTime]: true,
            });
            navigatePageWithWelcomeScreen(Routes.onBoarding);
            break;
          // case InitTripCases.ErrorNoToken:
          default:
            addToast('OOPS! The link is broken. Please make sure the link is correct', {
              appearance: 'error',
            });
            navigatePageWithWelcomeScreen(Routes.page404);
            break;
        }
      }
    }

    initTripDetails();
  }, []);

  // useEffect(() => {
  //   async function initTripDetails() {
  //     let firstTimeIndicator = false;
  //     try {
  //       //Save the user's redirection source
  //       const paramRedirectSrc: string | null = ParamsService.getRedirectionSource();
  //       if (paramRedirectSrc) StorageService.local.save.redirectionSource(paramRedirectSrc);

  //       //Check whether it's a new client configuration
  //       const clientName: string | null = ParamsService.getClient();
  //       let updatedClient: IClientDesign = clientDesign;
  //       if (clientName && clientName !== clientDesign.name) {
  //         updatedClient = await updateClientDesign();
  //       }

  //       //Change token in case of a new one
  //       let cachedToken: string | null = StorageService.local.get.token();
  //       const paramToken: string | null = ParamsService.getToken();
  //       // if (!paramToken && !cachedToken) throw new Error('User token is Missing');
  //       if (paramToken && paramToken !== cachedToken) {
  //         StorageService.session.clear();
  //         StorageService.local.save.token(paramToken);
  //         cachedToken = paramToken;
  //         firstTimeIndicator = true;
  //       }

  //       //CASE 0: NO Valid token , NO Cached Trip
  //       Mixpanel.initAndIdentify();
  //       Mixpanel.register({
  //         [MP_Props.Desktop]: isDesktop,
  //         [MP_Props.client]: updatedClient.name || 'none',
  //         [MP_Props.tokenless]: true,
  //       });
  //       if (!cachedToken) {
  //         //create an random disctint that will be merged after create trip
  //         if (clientDesign.TOKENLESS_ONBOARDING) {
  //           Mixpanel.track(MP_Events.AppOpen, {
  //             [MP_Props.tripCreated]: false,
  //             [MP_Props.firstTime]: false,
  //           });
  //           navigatePageWithWelcomeScreen(Routes.onBoarding);
  //           return;
  //         } else {
  //           throw new Error('User token is Missing');
  //         }
  //       }

  //       // Valid token
  //       const cachedTripDetails: ITripDetails_Converted = StorageService.session.get.tripDetails();
  //       if (cachedTripDetails) {
  //         // CASE 1: YES- Valid Token, YES- Cached Trip - Same session, no need to track analytics
  //         setTripDetailsWrapper(cachedTripDetails, true);
  //         navigatePageWithWelcomeScreen(Routes.feed);
  //       } else {
  //         // CASE 2: YES- Valid Token, NO- Cached Trip
  //         StorageService.session.clear();
  //         try {
  //           await updateTripDetails();
  //           Mixpanel.track(MP_Events.AppOpen, {
  //             // [MP_Props.client]: clientName,
  //             [MP_Props.firstTime]: false,
  //             [MP_Props.tripCreated]: true,
  //           });
  //           navigatePageWithWelcomeScreen(Routes.feed);
  //         } catch (error: any) {
  //           if (error.message === '400') {
  //             Mixpanel.track(MP_Events.AppOpen, {
  //               // [MP_Props.client]: clientName,
  //               [MP_Props.firstTime]: firstTimeIndicator,
  //               [MP_Props.tripCreated]: false,
  //             });
  //             navigatePageWithWelcomeScreen(Routes.onBoarding);
  //           } else {
  //             navigatePageWithWelcomeScreen(Routes.page404);
  //           }
  //         }
  //       }
  //     } catch {
  //       addToast('OOPS! Link is broken, please enter the full link', {
  //         appearance: 'error',
  //       });
  //       history.push(Routes.page404);
  //     } finally {
  //     }
  //   }
  //   initTripDetails();
  // }, []);

  return (
    // <ClientContext.Provider value={{ ClientDesign: client, updateClientDesign }}>
    <ThemeProvider theme={theme}>
      <TripDetailsContext.Provider
        value={{
          tripDetails,
          setTripDetailsWrapper,
          updateTripDetails,
          navigatePageWithWelcomeScreen,
        }}
      >
        {children}
      </TripDetailsContext.Provider>
    </ThemeProvider>
    // </ClientContext.Provider>
  );
}
