import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker/Marker';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { TRIP_ITEMS } from '../../../core/utils/enums';
import { useFeed } from '../../pages/Feed/useFeed';
import ReactDependentScript from 'react-dependent-script';

// interface IProps {
//     locations?: IItemLocation[];
//     zoom?: number;
//     center?: ILocation;
// }

interface IMapItem {
    location: ILocation,
    type: TRIP_ITEMS,
    index: number;
}
export default function Map() {
    // const [{ map, maps }, setData] = useState<any>({ map: undefined, maps: undefined })
    const [mapItems, setMapItems] = useState<IMapItem[]>([])
    // const [poligons, setPoligons] = useState()
    // const coordinates = locations.map((item: IItemLocation) => item.location)
    const { tripDetails: { tripDays } } = useTripDetails()
    const { state: { currentDayIndex, selectedItemBook } } = useFeed()

    const dayData = tripDays[currentDayIndex];
    useEffect(() => {
        const newItems: IMapItem[] = []
        let index: number = 1;
        dayData?.items?.forEach((item) => {
            if (item.type !== TRIP_ITEMS.Commute) {
                const { location, type } = item
                newItems.push({ location, type, index })
                index++;
            }
        })
        setMapItems(newItems);

        // var bounds = new google.maps.LatLngBounds();
        // for (var i = 0; i < mapItems.length; i++) {
        //     bounds.extend(mapItems[i].location);
        // }

        // map.fitBounds(bounds);
        // async function apiIsLoaded() {
        //     if (poligons) {
        //         //@ts-ignore
        //         poligons?.setMap(null)
        //     }
        //     if (maps) {
        //         setPoligons(new maps.Polygon({
        //             paths: mapItems.map(item => item.location),
        //             strokeOpacity: 0.6,
        //             strokeColor: 'black',
        //             strokeWeight: 2,
        //             fillOpacity: 0
        //         }))
        //     }
        //     if (map) {
        //         //@ts-ignore
        //         poligons?.setMap(map);
        //     }
        // }
        // apiIsLoaded();
    }, [currentDayIndex])

    // const AttractionsSlider = useCallback(() => {
    //     return <SliderContainer>
    //         {dayItems?.map(
    //             //@ts-ignore
    //             (item: ITripItem_Resturant | ITripItem_Attraction, index: number) => {
    //                 if (item.type === TRIP_ITEMS.Commute) return null;
    //                 // const selected: boolean = item._id === selectedAttraction._id
    //                 return (
    //                     <ItemPreview key={item._id}
    //                         onClick={(() => alert('direct to attracion location'))}
    //                     // onClick={(() => handleAttractionClick(item))}
    //                     >
    //                         <div style={{
    //                             background: `url(${item.imgURL}) center no-repeat`,
    //                             height: '74px', borderRadius: '8px 8px 0 0',
    //                             position: "relative", backgroundSize: "cover"
    //                         }}>
    //                             <span >{index}
    //                                 {/* <span className={Style.itineraryCount}>{index} */}
    //                             </span>
    //                             {item.tags?.slice(0, 2)
    //                                 ?.map((t) => <Tag key={t}>{t}</Tag>)}
    //                         </div>

    //                         <Content>
    //                             <ItemTitle >{item.name}</ItemTitle>
    //                             <Rank {...item.rank} />
    //                         </Content>
    //                     </ItemPreview>
    //                 );
    //             })}
    //     </SliderContainer>
    // }, [])

    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY

    const center = selectedItemBook?.location || dayData?.items[0]?.location || dayData?.items[1]?.location

    return API_KEY ? <ReactDependentScript scripts={[`https://maps.googleapis.com/maps/api/js?key=${API_KEY}`]}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultZoom={12}
            center={center}
            options={{
                disableDefaultUI: true,
                mapTypeControl: false,
                streetViewControl: true,
                styles: [{
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [{ visibility: 'on' }]
                }],
                fullscreenControl: false,
            }}
            // onGoogleApiLoaded={({ map, maps }) => { setData({ map, maps }) }}
            yesIWantToUseGoogleMapApiInternals={true}>
            {mapItems.map(({ location: { lat, lng }, type, index }) => {
                return (
                    <Marker
                        // selected={val.location.lng === center.lng && val.location.lat === center.lat}
                        key={lat + lng}
                        attractionType={type}
                        lat={lat} lng={lng}
                        index={index}
                    // zoom={zoom}
                    // text={val.index + 1}
                    />
                )
            })}
        </GoogleMapReact>
    </ReactDependentScript>
        : null
}

// const ItemPreview = styled.div`
//     height: 167px;
//     width: 296px;
//     min-width: 296px;
//     margin-right: 12px;
//     background: #fff;
//     -webkit-border-radius: 8px;
//     -moz-border-radius: 8px;
//     border-radius: 8px;
//     margin: 12px 12px 0 12px;
// `;

// const SliderContainer = styled.div`
//     z-index: 9999;
//     position: absolute;
//     bottom: 1rem;
//     display: flex;
//     width: 100%;
//     flex-direction: row;
//     align-items: center;
//     overflow-x: scroll;
//     overflow-y: hidden;
//     box-sizing: border-box;
//     padding: 0 1rem;
// `;

