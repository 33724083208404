import React from "react";
import { useCallback } from "react";
import { TRIP_ITEMS } from "../../../core/utils/enums";
import { useTripDetails } from "../../../providers/TripDetailsProvider";
import { useFeed } from "../../pages/Feed/useFeed";
import Rank from "../../shared/Rank";
import styled from "styled-components/macro";
import { Collapse } from "@material-ui/core";

const AttractionsPreviewSlider: React.FC = () => {
  const { state: { currentDayIndex, showMapView, }, dispatch } = useFeed()
  const { tripDetails: { tripDays }, } = useTripDetails()
  const dayItems = tripDays[currentDayIndex]?.items;

  function handleClickOnPreview(item: ITripItem_Resturant | ITripItem_Attraction) {
    dispatch({ type: 'SELECTED_ITEM_BOOK', payload: item })
  }
  const AttractionsSlider = useCallback(() => {
    let ACTIVITIES_COUNTER: number = 0;
    return <SliderContainer>
      {dayItems?.map(item => {
        if (item.type === TRIP_ITEMS.Commute) return null;
        // const selected: boolean = item._id === selectedAttraction._id
        ACTIVITIES_COUNTER++;
        return (
          <ItemPreview key={item._id}
            // onClick={(() => alert('direct to attracion location'))}
            onClick={() => handleClickOnPreview(item)}
          >
            <div
              style={{
                background: `url(${item.imgURL}) center no-repeat`,
                height: '74px', borderRadius: '8px 8px 0 0',
                position: "relative", backgroundSize: "cover",
                padding: '5px'
              }}>
              <ItemIndex >{ACTIVITIES_COUNTER}</ItemIndex>
              {item.tags?.slice(0, 2)
                ?.map((t) => <Tag key={t}>{t}</Tag>)}
            </div>

            <Content>
              <ItemTitle >{item.name}</ItemTitle>
              <Rank {...item.rank} />
            </Content>
          </ItemPreview>
        );
      })}
    </SliderContainer>
  }, [currentDayIndex])

  return (
    <Collapse in={showMapView} orientation="vertical" >
      <AttractionsSlider />
    </Collapse>)
}
export default AttractionsPreviewSlider;

const ItemIndex = styled.span`
  background-color:${p => p.theme.primary.bgColor};
  color:${p => p.theme.primary.textColor};
  padding:2px 5px;
  width: min-content;
  border-radius: 5px;
`;

const ItemPreview = styled.div`
  height: 167px;
  min-width: 220px;
  margin-right: 12px;
  background: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  margin: 12px 12px 0 12px;
`;

const ItemTitle = styled.span``;


const Content = styled.div`
  padding:1rem;
`;


const Tag = styled.span`
    display: inline-block;
    background: white;
    padding: 5px;
    border-radius: 6px;
    margin: 2px;
    font-size: 12px;
    width: fit-content;
`;

const SliderContainer = styled.div`
    z-index: 9999;
    position: absolute;
    bottom: 2rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    box-sizing: border-box;
`;
