
import { Beforeunload } from 'react-beforeunload';
import Page from "../../shared/Page";
import { LoaderTypes, OnBoardingStates } from "../../../core/utils/enums";
import { useOnboarding } from './useOnboarding';

export default function Onboarding() {

  const {
    RenderedPage, canContinue,
    handleLeavingPage, dispatch,
    handleBack, nextButtonHandler, state,
    state: { mode, loading, dateRange }
  } = useOnboarding()

  const [startDate,] = dateRange;

  const { component: RenderedComponent, subtitle, title } = RenderedPage
  return (
    <>
      <Beforeunload onBeforeunload={(event) => handleLeavingPage(event)} />
      <Page
        appbar={{
          title: title || 'Plan Your Trip',
          subtitle: subtitle || 'Just a few questions about your stay..',
          handleBack,
          backOrClose: (mode === OnBoardingStates.InterestsPage) ||
            (mode === OnBoardingStates.TripTypesPage && startDate !== null) ?
            'BACK' : undefined
        }}
        footer={{
          button: {
            text: 'Next',
            disabled: !canContinue,
            onClick: nextButtonHandler
          }
        }}
        loading={loading}
        loaderType={LoaderTypes.Images}>
        <RenderedComponent state={state} dispatch={dispatch} />
      </Page>
    </>)
};