import { Collapse, Dialog } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { Tokenless } from '../../../assets/images/images';
import { delay } from '../../../core/utils/common';
import { validateCode, validateEmail } from '../../../core/utils/validations';
import { ErrorAlert } from '../../shared/Alerts';
import Button from '../../shared/Button';
import CheckIcon from '@material-ui/icons/Check';
import StorageService from '../../../core/services/storage';
import {
  sendVerificationCode,
  sendVerificationEmail,
} from '../../../core/services/emailVerification';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { useFeed } from './useFeed';

enum STEPS {
  One,
  Two,
  Three,
}

export default function VerificationDialog() {
  const {
    state: { openEmailDialog },
    dispatch,
  } = useFeed();
  const {
    tripDetails: {
      ClientDesign: { name: client },
      _id: trip_id,
    },
  } = useTripDetails();

  const [step, setStep] = useState<STEPS>(STEPS.One);

  const [emailValue, setEmailValue] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>();

  const [emailLoading, setEmailLoading] = useState<boolean>(false);
  const [emailSucess, setEmailSucess] = useState<boolean>(false);
  const [codeLoading, setCodeLoading] = useState<boolean>(false);

  const [codeValid, setCodeValid] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  function resetErrors(): void {
    setError(undefined);
  }

  function closeDialog() {
    StorageService.local.save.validatedEmail();
    resetErrors();
    // setStep(STEPS.One)
    dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: false });
    // setOpenDialog(false)
  }

  async function handleSendEmail() {
    try {
      if (!validateEmail(emailValue)) {
        throw new Error('not valid email');
      }
      setEmailLoading(true);
      resetErrors();
      await sendVerificationEmail({
        email: emailValue,
        trip_id,
        client,
      });
      setEmailLoading(false);
      setEmailSucess(true);
      await delay(3000);
      setStep(STEPS.Two);
    } catch (error) {
      setEmailLoading(false);
      setError('Please enter a valid e-mail');
    }
  }

  function handleCodeChange(value: string): void {
    const validcode: boolean = validateCode(value);
    if (validcode) {
      resetErrors();
      setCodeValue(value);
      setCodeValid(+value >= 1000);
    } else {
      setCodeValue((oldCodeValue) => oldCodeValue);
    }
  }
  function handleEmailChange(value: string): void {
    resetErrors();
    setEmailValue(value);
  }

  function handleResendEmail() {
    resetErrors();
    setStep(STEPS.One);
    setEmailSucess(false);
  }

  // Triggered when the user enters a verification code
  // loader - until response was returned
  // error- on wrong code / bad response
  async function handleCodeSubmission() {
    try {
      if (!codeValue || !validateCode(codeValue)) {
        throw new Error('not valid code');
      }
      resetErrors();
      setCodeLoading(true);
      await sendVerificationCode({
        verification_code: codeValue,
        email: emailValue,
        trip_id,
        client,
      });
      // throw new Error();
      setStep(STEPS.Three);
    } catch (error) {
      setError('Wrong Code');
    } finally {
      // await delay(2000);
      setCodeLoading(false);
    }
  }

  async function handleFinishVerification() {
    resetErrors();
    closeDialog();
  }

  function handleEditEmail(): void {
    resetErrors();
    setStep(STEPS.One);
    setEmailSucess(false);
  }
  const StepOne = (
    <>
      <Title>Welcome to your trip plan!</Title>
      <Description>
        To verify and save your personal trip, please insert your email address:
      </Description>

      <StyledCollapse in={!(emailLoading || emailSucess)}>
        {
          <StyledInput
            type="email"
            value={emailValue}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        }
      </StyledCollapse>
      {error && <ErrorAlert style={{ marginTop: '1rem' }}>{error}</ErrorAlert>}

      <Button
        loadingSpinner
        spinnerState={emailLoading}
        onClick={handleSendEmail}
        spinnerSize={20}
        disabled={emailSucess}
        text={emailSucess ? 'Sent' : 'Send'}
        Icon={emailSucess ? CheckIcon : null}
        style={{
          width: '50%',
          height: '1.8rem',
          color: 'white',
          fontWeight: emailSucess ? 600 : undefined,
          background: emailSucess ? '#388e3c' : '#69D6F4',
          marginTop: '1rem',
        }}
      />
    </>
  );

  const StepTwo = (
    <>
      <Title> Welcome back!</Title>
      <Description>Insert your code here:</Description>

      <Collapse in={!codeLoading}>
        <StyledCodeInput
          type="number"
          value={codeValue}
          onChange={(e) => handleCodeChange(e.target.value)}
        />
      </Collapse>

      {error && (
        <Alert variant="outlined" severity="error">
          {error}
        </Alert>
      )}
      <Button
        loadingSpinner
        spinnerState={codeLoading}
        onClick={handleCodeSubmission}
        spinnerSize={20}
        text="Done"
        disabled={!codeValid}
        style={{
          width: '50%',
          height: '1.8rem',
          background: '#FAC847',
          marginBottom: '0.5rem',
          marginTop: '0.5rem',
        }}
      />
      <span>Didn’t get the email?</span>
      <div>
        <ActionSpan onClick={handleResendEmail}>Resend</ActionSpan> or{' '}
        <ActionSpan onClick={handleEditEmail}>Edit Email</ActionSpan>
      </div>
    </>
  );

  const StepThree = (
    <>
      <Title> Great!</Title>
      <Description>
        Your trip is now verified.
        <br />
        Please check your email to find <b>your personal trip link</b>.
      </Description>

      <Button
        loadingSpinner
        spinnerState={codeLoading}
        onClick={handleFinishVerification}
        spinnerSize={20}
        text="Thanks"
        disabled={!codeValid}
        style={{
          width: '50%',
          height: '1.8rem',
          background: '#FAC847',
          marginBottom: '0.5rem',
          marginTop: '1rem',
        }}
      />
    </>
  );

  const StepsDic: { [key in STEPS]: { component: any; img: any } } = {
    [STEPS.One]: { component: StepOne, img: Tokenless.WomanPhotographer },
    [STEPS.Two]: { component: StepTwo, img: Tokenless.KidPhotographer },
    [STEPS.Three]: { component: StepThree, img: Tokenless.KidPhotographer },
  };

  return (
    <StyledDialog open={openEmailDialog} maxWidth="xs">
      <Container imgSrc={StepsDic[step].img}>
        <Content>{StepsDic[step].component}</Content>
        <Image src={StepsDic[step].img} />
      </Container>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  && {
    /* display: grid; */
    /* grid-template-columns: 70% 30%; */
    /* min-width: 200px; */
  }
`;

const Image = styled.img`
  width: 35%;
`;

const StyledCollapse = styled(Collapse)`
  && {
    width: 100%;
  }
`;

const ActionSpan = styled.span`
  font-weight: 700;
  text-decoration: underline;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 0 1rem 1rem;
`;

const Container = styled('div')<{ imgSrc?: any }>`
  background-color: #d3d3d3ac;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  /* background-image: url(${(p) => p.imgSrc}) ;
  background-size: 20vh;
  background-repeat: no-repeat;
  background-position: 120% 125%; */
`;

const Description = styled.span`
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
`;

const Title = styled.div`
  width: 100%;
  font-weight: 600;
  text-align: left;
  /* margin-bottom:0.5rem; */
`;

const StyledInput = styled.input`
  border: 1px solid #69d6f4;
  border-radius: 5px;
  width: 80%;
  height: 2rem;
  font-size: 0.8rem;
`;

const StyledCodeInput = styled.input`
  border: 1px solid #fac847;
  border-radius: 5px;
  width: 70%;
  height: 4rem;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.3rem;
  //Hide Input Arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
