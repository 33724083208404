import { Endpoints } from "../utils/endpoints";
import Calls from "./calls";

const { bridgify: { post } } = Calls;

interface ILogTiqetsOrder {
  trip_id: ITripDetails_Converted["_id"];
  itinerary_item_id: EventDetails_Attraction["id"];
  external_id: string; //the external_id?
  window_open_time: string; //YY-MM-DD HH:MM:SS;
  window_close_time: string; //YY-MM-DD HH:MM:SS
}
export const logTiqetsOrder = async (data: ILogTiqetsOrder): Promise<void> =>
  post(Endpoints.logTiqetsOrder, data);