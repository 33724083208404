import styled from 'styled-components/macro';
import { Images } from '../../assets/images/images';
import { useTripDetails } from '../../providers/TripDetailsProvider';

export default function Page404() {
  const {
    tripDetails: {
      ClientDesign: { Logo: LogoSrc },
    },
  } = useTripDetails();
  return (
    <Container>
      <Content>
        <LogoContainer>{<Logo src={Images.baggage} alt="client-logo" />}</LogoContainer>

        <Title>OOPS! </Title>
        <SubTitle>TRIP NOT FOUND </SubTitle>
        <Description>In order to enter your trip, Please enter your full link!</Description>

        {LogoSrc && <Logo src={LogoSrc} alt="client-logo" />}
      </Content>
    </Container>
  );
}
const Content = styled.div`
  background: #fbfbfb;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
`;
const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;

const Description = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: #0e1738;
  text-align: center;
  margin-bottom: 3rem;
`;
const Title = styled.div`
  font-size: 3rem;
  font-weight: 400;
  color: #0e1738;
`;
const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #0e1738;
  margin-bottom: 3rem;
`;
const LogoContainer = styled.div`
  /* margin-top: 50%; */
`;
const Logo = styled.img`
  max-height: 30vh;
  max-width: 80%;
  height: auto;
  width: fit-content;
`;
