import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://ab7ee45282584ae3ad7b8a6ce7f1fadf@o508821.ingest.sentry.io/5864583",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV || 'test',
        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <BrowserRouter>
        <ToastProvider>
            <App />
        </ToastProvider>
    </BrowserRouter>
    , document.getElementById("root")
);