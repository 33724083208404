import Style from './TripPlanningPage.module.scss';
import TripPlans from '../TripPlans/TripPlans';
import { IOnBoardingState, TOnboardingActions } from '../useOnboarding';

type IProps = {
  state: IOnBoardingState,
  dispatch: React.Dispatch<TOnboardingActions>;
}
// Trip Planning trip --> Made for dubai!
export default function TripPlanningPage({
  state, dispatch }: IProps) {
  const MAX_OF_DAYS_SELECTOR: number = 14;

  function changePlanHandler(index: number): void {
    dispatch({ type: "SELECTED_PLAN", payload: { selectedTripPlan: index } });
  }
  function handleNumOfDaysChange(): void {
    // dispatch({ type: "DATE_RANGE", payload: state.dateRange[0] + days });
  }

  return (

    <div className={Style.TripPlanning}>
      <div className={Style.description1}>
        <label htmlFor="numberOfNights"><p>How long is your stay?</p></label>
        <select
          name="numberOfNights"
          id="days"
          onChange={handleNumOfDaysChange}
          className={Style.TripDaysSelector}
          value={2}        >
          {Array.from(Array(MAX_OF_DAYS_SELECTOR + 1)).map((_, index) =>
            <option key={index} value={index} className={Style.TripDaysOption}>{index === 0 ? '-' : `${index}`}</option>
          )}
        </select>Days
      </div>
      <div className={Style.description2}>
        <label htmlFor="TripPlans"><span>Who are you traveling with?</span></label>
      </div>

      <TripPlans
        changePlanHandler={(e) => changePlanHandler(e)}
        selectedPlan={state.selected_plan} />
    </div>
  )
}

