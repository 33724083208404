import { Collapse } from '@material-ui/core';
import styled from 'styled-components/macro';
import StorageService from '../../core/services/storage';
import { delay } from '../../core/utils/common';
import { TripItemsDic } from '../../core/utils/dictionaries';
import { CardBorderColors, TRIP_ITEMS } from '../../core/utils/enums';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import DaysSlider from '../pages/Feed/DaysSlider';
import TimelineBox from '../pages/Feed/TimelineBox';
import { useFeed } from '../pages/Feed/useFeed';
import AccomandationItem from './AccomandationItem';
import FeedTopBar from './FeedTopBar';
interface IProps {
  actionIcons: IActionIcons[];
  onAttractionDelete: (index: number) => Promise<void>;
}
export default function Timeline({ actionIcons, onAttractionDelete }: IProps) {
  const {
    state: { currentDayIndex, showMapView },
    dispatch,
  } = useFeed();
  const {
    tripDetails: {
      tripDays,
      ClientDesign: { TOKENLESS_ONBOARDING },
      ClientDesign: { PrimaryColor, SecondaryColor },
    },
  } = useTripDetails();

  /**Trigger the scroll listener for open email-verification dialog, only on the following conditions:
   * 1. It's a TOKENLESS client
   * 2. The user hasn't verified his email yet
   */
  const triggerScrollListener: boolean =
    TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail();

  async function OpenEmailValidationDialog() {
    if (TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail()) {
      await delay(2000);
      dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: true });
    }
  }

  // useEffect(() => {
  //   async function OpenEmailValidationDialog() {
  //     console.log('end');
  //     await delay(2000);
  //     dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: true })
  //     window.removeEventListener('scroll', OpenEmailValidationDialog)
  //   }

  //   async function initEmailVerificationDialog() {
  //     console.log('start');

  //     await window.addEventListener('scroll', OpenEmailValidationDialog)
  //   }

  //   if (TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail()) {
  //     initEmailVerificationDialog()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  let ACTIVITIES_COUNTER: number = 0;

  const RenderComponent = (item: ITripDay['items'][0], index: number) => {
    const { type } = item;
    const Component = TripItemsDic[type].component;

    const cardBorderColor =
      item.cardBorderColor === CardBorderColors.PaidAttraction
        ? PrimaryColor.bgColor
        : item.cardBorderColor === CardBorderColors.FreeAttraction
          ? SecondaryColor.bgColor
          : '#9E9E9E';

    if (type !== TRIP_ITEMS.Commute) {
      ACTIVITIES_COUNTER++;
    }
    return (
      <TimelineBox
        last={index === tripDays[currentDayIndex]?.items.length - 1}
        key={item._id}
        boxType={type}
        cardBorderColor={cardBorderColor}
      >
        <Component
          item={item}
          ACTIVITIES_COUNTER={ACTIVITIES_COUNTER}
          TIMELINE
          onDelete={() => onAttractionDelete(index)}
          last={index === tripDays[currentDayIndex]?.items.length - 1}
        />
      </TimelineBox>
    );
  };

  return (
    <Container
      onScrollCapture={triggerScrollListener ? OpenEmailValidationDialog : undefined}
      showMapView={showMapView}
    >
      <TripDetailsContainer>
        <FeedTopBar actionIcons={actionIcons} />
        <DaysSlider />
      </TripDetailsContainer>

      <ItemsList collapse={showMapView}>
        <Collapse in={!showMapView} orientation="horizontal">
          {!showMapView && (
            <ItemsContainer>
              <AccomandationItem />
              {tripDays[currentDayIndex]?.items.map((dayItinerary, index) =>
                RenderComponent(dayItinerary, index),
              )}
            </ItemsContainer>
          )}
        </Collapse>
      </ItemsList>
    </Container>
  );
}

const ItemsContainer = styled.div`
  background-color: #ffffffe6;
  padding: 1rem;
  border-radius: 10px;
`;

const Container = styled.div<{ showMapView: boolean }>`
  position: absolute;
  z-index: 3;
  height: ${(p) => (p.showMapView ? '' : '100%')};
  width: min-content;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const TripDetailsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const ItemsList = styled('div') <{ collapse: boolean }>`
  height: 100%;
  /* padding:1rem;
    background-color: #ffffffb0; */
  z-index: ${(p) => (p.collapse ? '0' : '2')};
  width: min-content;
`;
