import styled, { CSSProperties } from "styled-components"
import Alert from '@material-ui/lab/Alert';
import UpdateIcon from '@material-ui/icons/Update';

export function SuccessAlert({ children }) {

  return (
    <StyledSuccessAlert severity="success" variant="outlined" >
      {children}
    </StyledSuccessAlert>
  )
}


const StyledSuccessAlert = styled(Alert)`
&&{
  align-items: center;
  align-content: center;
  font-size: 0.8rem;
  width: 100%;
  padding: 0px 1rem;
  font-weight: 500;
  border: 0;
}
`
export function WarningAlert({ children }) {

  return (
    <StyledWarningAlert icon={<UpdateIcon />} severity="warning">
      {children}
    </StyledWarningAlert>
  )
}


const StyledWarningAlert = styled(Alert)`
&&{
  align-items: center;
  align-content: center;
  font-size: 0.8rem;
  width: fit-content;
  padding: 0px 1rem;
  font-weight: 400;
  border-radius:5px;
}
`

export function ErrorAlert({ children, style }: { children: any, style?: CSSProperties }) {

  return (
    <StyledErrorAlert severity="error" style={style}>
      {children}
    </StyledErrorAlert>
  )
}


const StyledErrorAlert = styled(Alert)`
&&{
  align-items: center;
  align-content: center;
  font-size: 0.9rem;
  width: fit-content;
  padding: 0px 1rem;
  font-weight: 500;
  border-radius:5px;
}
`