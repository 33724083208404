import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  navigationHandler,
  navigateToOrdersPage,
  scrollWindowToTop,
} from '../../../core/utils/feed';
import {
  AttractionStates,
  BookedStatuses,
  HASH,
  TOAST_TYPES,
  TRIP_ITEMS,
} from '../../../core/utils/enums';
import Mixpanel, { MP_Events, MP_Pages, MP_Props } from '../../../core/services/mixpanel';
import { SvgIconTypeMap } from '@material-ui/core/';
import { addAttractionToDayItinerary } from '../../../core/services/trip';
import { useToasts } from 'react-toast-notifications';
import StorageService from '../../../core/services/storage';
import { Endpoints } from '../../../core/utils/endpoints';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import ItemDialog from './ItemInfoDialog';
import ItemCard from './ItemCard';
import { MusementToItemButtonStateMapper } from '../../../core/utils/dictionaries';
import { Icons } from '../../../assets/images/images';
import {
  formatDateto_YYYY_MM_DD_HH_MM_SS,
  setHash,
  turnSpacesToUnderline,
} from '../../../core/utils/common';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { useFeed } from '../Feed/useFeed';
import { logTiqetsOrder } from '../../../core/services/tiqets';
// import { useLayout } from "../../../providers/LayoutProvider";

export interface IItemButtonsDic {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonColor: string;
  textColor?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> | null | string;
  disabled?: boolean;
}

interface IProps {
  item: ITripItem_Resturant | ITripItem_Attraction;
  TIMELINE?: boolean;
  onDelete?(id: number | null): void;
  componentLocation?: 'Feed' | 'Explore';
  isAttractionAlreadyInTrip?: boolean;
  last?: boolean;
  ACTIVITIES_COUNTER?: number;
}

export default function Item({
  item,
  item: { _id, location, name, orderPage, tags, type },
  last,
  ACTIVITIES_COUNTER,
  onDelete = () => {},
  TIMELINE,
  isAttractionAlreadyInTrip = true,
  componentLocation = 'Feed',
}: IProps) {
  // const uuid = '37cdf113-43d7-43df-ab38-4bc6993b4729'  //pickupmultiple ticket options
  // const uuid = '82f6f46c-3923-4510-b5ba-b50aad624eb1' //open date ticket
  // const uuid = '0f5821c5-2046-11e7-9cc9-06a7e332783f'//need confirmation
  // const uuid = '1714c6a7-2046-11e7-9cc9-06a7e332783f' //extra customer data
  // const uuid = '0420c908-981e-4e27-a40f-767e8b66574a' //participants info
  // const uuid = '165fcd0d-2046-11e7-9cc9-06a7e332783f' //participants info and extra customer data
  // const uuid = '101dcb56-2046-11e7-9cc9-06a7e332783f' //languages
  // const uuid = 'd38cea83-02f6-47b3-abd6-5abbf812e27a' //day-based activity
  // const uuid = 'c4d3c4a2-d2b1-4320-a2cc-e2244df976d8' // languages + tour optins
  // const uuid = 'd752296e-176f-413c-a61d-0b4931a54be8' // languages + tour optins
  // const uuid = 'a0eb01b4-588c-4a03-9568-efc971bcc857' // multiple options, single time + timeslots
  // const uuid = '0f608c73-2046-11e7-9cc9-06a7e332783f' // timeslots + languages
  //@ts-ignore
  const bookingStatus: BookedStatuses = item?.bookingStatus || BookedStatuses.NotBooked;
  //@ts-ignore
  const uuid: string | null = item?.uuid ? item.uuid : null;
  //@ts-ignore
  const source: ITripItem_Attraction['source'] | null = item?.source ? item.source : null;
  //@ts-ignore
  const pickup: IPickupDetails | null = item?.pickup ? item.pickup : null;
  const [isInTrip, setisInTrip] = useState(isAttractionAlreadyInTrip);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const dialogRef = useRef<HTMLHeadingElement>(null);

  const {
    tripDetails: {
      _id: trip_id,
      tripDays,
      ClientDesign: { PrimaryColor, SecondaryColor, TertiaryColor, name: clientName },
    },
    updateTripDetails,
  } = useTripDetails();

  const {
    dispatch,
    state: { openTiqetsBookingDialog },
  } = useFeed();
  const { addToast } = useToasts();
  // const { isMobile } = useLayout()
  useEffect(() => {
    scrollWindowToTop();
  }, []);

  function handleOpenItemDialog(): void {
    setHash(HASH.Info, _id);
    Mixpanel.track(MP_Events.ItemCard, {
      Page: MP_Pages.Feed,
      tags: tags.join(', '),
      title: name,
      [MP_Props.client]: clientName,
    });
  }

  function handleNavigation(event) {
    event.stopPropagation();
    Mixpanel.track(MP_Events.Navigate, {
      Page: MP_Pages.Feed,
      tags: tags.join(', '),
      title: name,
      [MP_Props.client]: clientName,
    });
    let locationToNavigate: ILocation = location;
    // Pickup?--> navigate to pickup location
    if (pickup) {
      const { lat, lng } = pickup;
      locationToNavigate = { lat, lng };
    }
    navigationHandler(locationToNavigate);
  }

  async function handleBookNow(event) {
    event.stopPropagation();
    if (uuid) {
      Mixpanel.track(MP_Events.BookNow, {
        Page: MP_Pages.Feed,
        tags: tags.join(', '),
        title: name,
        [MP_Props.client]: clientName,
      });
      dispatch({ type: 'SELECTED_ITEM_BOOK', payload: item });
      setHash(HASH.BookingInfo, turnSpacesToUnderline(name));
      if (source === 'Tiqets' || source === 'TicketMaster') {
        dispatch({ type: 'TOGGLE_TIQETS_DIALOG' });
      } else if (source === 'Musement') {
        dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' });
      }
    } else {
      if (!orderPage) return;
      navigateToOrdersPage(orderPage);
    }
  }

  function setAttractionState(): AttractionStates {
    //If musement activity? map depends on the activity state
    if (source === 'Tiqets') return AttractionStates.BookNow;
    if (uuid && bookingStatus !== BookedStatuses.NotBooked) {
      return MusementToItemButtonStateMapper[bookingStatus];
    } else {
      if (!isInTrip) return AttractionStates.AddToTrip;
      if (orderPage) return AttractionStates.BookNow;
      return AttractionStates.Navigate;
    }
  }

  async function handleAddToTrip(event: MouseEvent) {
    event.stopPropagation();
    setShowSpinner(true);
    const payload = {
      day_itinerary_id: tripDays[StorageService.session.get.currentDay()]._id,
      event_id: _id,
    };
    // calling api to add itinerary for the day
    try {
      await addAttractionToDayItinerary(Endpoints.addEvent, payload);
      Mixpanel.track(MP_Events.AddToTrip, {
        Page: MP_Pages.InfoPage,
        tags: tags.join(', '),
        title: name,
        [MP_Props.client]: clientName,
      });
      setShowSpinner(false);
      addToast('The Attraction has been added to your trip!', {
        appearance: TOAST_TYPES.Success,
        autoDismiss: true,
      });
      setisInTrip(true);
      updateTripDetails();
    } catch (error) {
      addToast('OOPS! We had trouble with adding the attraction', {
        appearance: TOAST_TYPES.Error,
        autoDismiss: true,
      });
    }
  }

  const attractionState: AttractionStates = setAttractionState();

  const ButtonsDic: { [key in AttractionStates]: IItemButtonsDic } = {
    [AttractionStates.AddToTrip]: {
      text: 'Add To Trip',
      buttonColor: TertiaryColor.bgColor,
      textColor: TertiaryColor.textColor,
      onClick: (e: any) => handleAddToTrip(e),
      icon: null,
    },
    [AttractionStates.BookNow]: {
      text: bookingStatus === BookedStatuses.Pending ? 'Awaiting Approval' : 'Book Now',
      buttonColor: type === TRIP_ITEMS.Attraction ? PrimaryColor.bgColor : SecondaryColor.bgColor,
      textColor: type === TRIP_ITEMS.Attraction ? PrimaryColor.textColor : SecondaryColor.textColor,
      onClick: (e) => {
        handleBookNow(e);
      },
      icon: null,
      disabled: bookingStatus === BookedStatuses.Pending,
    },
    [AttractionStates.Navigate]: {
      text: 'Navigate',
      buttonColor: 'white',
      onClick: (e) => handleNavigation(e),
      // icon: DirectionsOutlinedIcon
      icon: Icons.navigate,
    },
  };

  return (
    <>
      <ItemCard
        item={item}
        ButtonProps={ButtonsDic[attractionState]}
        showSpinner={showSpinner}
        handleOpenItemDialog={handleOpenItemDialog}
        componentLocation={componentLocation}
        onDelete={onDelete}
        ACTIVITIES_COUNTER={ACTIVITIES_COUNTER}
        TIMELINE={TIMELINE}
        last={last}
      />

      <ItemDialog item={item} ButtonProps={ButtonsDic[attractionState]} showSpinner={showSpinner} />
    </>
  );
}
