import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { IOnBoardingState, TOnboardingActions } from './useOnboarding';
import { TextField, Collapse, Alert, CircularProgress } from '@material-ui/core';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import moment from 'moment';
import { ErrorAlert } from '../../shared/Alerts';
import { validateCode, validateEmail } from '../../../core/utils/validations';
import {
  OnboardingLoginToExistingTrip,
  resendCode,
} from '../../../core/services/emailVerification';
import Button from '../../shared/Button';
import { Routes } from '../../../core/utils/endpoints';
import StorageService from '../../../core/services/storage';
import CheckIcon from '@material-ui/icons/Check';
import { delay } from '../../../core/utils/common';

import SelectSearch, { SelectedOptionValue } from 'react-select-search';
import Fuse from 'fuse.js';
import { Icons } from '../../../assets/images/images';

type IProps = {
  state: IOnBoardingState;
  dispatch: React.Dispatch<TOnboardingActions>;
};

export default function StayDetailsPage({ state: { dateRange, selectedHotel }, dispatch }: IProps) {
  const {
    navigatePageWithWelcomeScreen,
    updateTripDetails,
    tripDetails: {
      ClientDesign: {
        name: client,
        HOTEL_SELECTION,
        DATE_SELECTION,
        TOKENLESS_ONBOARDING,
        hotelList,
        SecondaryColor: { bgColor, textColor },
      },
    },
  } = useTripDetails();
  const [startDate, endDate] = dateRange;

  const [emailValue, setEmailValue] = useState<string>('');
  const [codeValue, setCodeValue] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const [codeValid, setCodeValid] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [resendLoading, setResendLoading] = useState(false);
  const [showSent, setShowSent] = useState<boolean>(false);

  function resetErrors(): void {
    setError(undefined);
  }

  async function handleSendEmail() {
    try {
      if (!validateEmail(emailValue) || !codeValue) {
        throw new Error('not valid email');
      }
      setLoading(true);
      resetErrors();
      // TODO: fetch token on sucess and redirect
      const [response] = await OnboardingLoginToExistingTrip({
        email: emailValue,
        verification_code: codeValue,
      });
      const { token } = response;
      if (!token || token.length === 0) throw new Error('invalid token');

      // const  = loginToTripResponse;
      StorageService.local.save.token(token);
      StorageService.local.save.validatedEmail();
      await updateTripDetails();
      navigatePageWithWelcomeScreen(Routes.feed);
      // redirect to `/?rt=${token}`
    } catch (error: any) {
      setError('OOPS! Invalid email or code.');
    } finally {
      setLoading(false);
    }
  }

  // const [error, setError] = useState<boolean>(false)
  function handleHotelChange({ hotel }: ISelectHotelOption) {
    if (hotel) {
      dispatch({ type: 'HOTEL_NAME', payload: hotel });
    }
  }
  function handleDateRangeChange(dateRange: [Date | null, Date | null]) {
    dispatch({ type: 'DATE_RANGE', payload: dateRange });
  }

  async function handleResend() {
    try {
      if (!validateEmail(emailValue)) {
        throw new Error('not valid email');
      }
      setResendLoading(true);
      await resendCode({ email: emailValue, client });
    } catch (error: any) {
      setError('OOPS! Invalid email');
    } finally {
      await delay(1000);
      setResendLoading(false);
      setShowSent(true);
      await delay(5000);
      setShowSent(false);
    }
  }
  /**
   * handles the changes in the code's input field
   * @param value
   */
  function handleCodeChange(value: string): void {
    const validcode: boolean = validateCode(value);
    if (validcode) {
      resetErrors();
      setCodeValue(value);
      setCodeValid(+value >= 1000);
    } else {
      setCodeValue((oldCodeValue) => oldCodeValue);
    }
  }

  function handleEmailChange(value: string): void {
    resetErrors();
    setEmailValue(value);
  }
  function fuzzySearch(options) {
    const fuse = new Fuse(options, {
      keys: ['name', 'groupName', 'items.name'],
      threshold: 0.3,
    });

    return (value) => {
      if (!value.length) {
        return options;
      }

      return fuse.search(value);
    };
  }

  // const MAX_OF_DAYS_SELECTOR: number = 14;
  if (!TOKENLESS_ONBOARDING) {
    dispatch({ type: 'NEXT' });
  }

  // const MAX_NUM_OF_DAYS = 10;
  // let max_day: Date | null = null
  // let min_day: Date | null = null
  // if (startDate) {
  //   max_day = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + MAX_NUM_OF_DAYS);
  // }
  // if (endDate) {
  //   var start = moment(startDate);
  //   var end = moment(endDate);
  //   const number_of_days = end.diff(start, "days") + 1;
  //   min_day = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - (MAX_NUM_OF_DAYS));
  // }

  // console.log([startDate?.getDay(), endDate?.getDay()])
  // const MAX_DAY: Date | null = startDate ? setDate(startDate + 7,) : null;
  const MAX_DATES_RANGE = 5;

  let VALID_DATES: boolean = true;
  if (DATE_SELECTION && startDate && endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    const numOfDays: number = end.diff(start, 'days') + 1;
    if (numOfDays > MAX_DATES_RANGE || numOfDays < 1) {
      VALID_DATES = false;
    }
  }

  interface ISelectHotelOption extends SelectedOptionValue {
    hotel: IHotel;
  }
  return (
    <Container url={Icons.searchIcon}>
      <Description> Please fill out the following fields:</Description>
      {HOTEL_SELECTION && (
        <HotelSelectionContainer>
          <SelectSearch
            options={
              hotelList?.map((hotel: IHotel) => {
                const { id, name, coming_soon } = hotel;
                return { value: id, name, disabld: coming_soon === true, hotel };
              }) || []
            }
            search
            filterOptions={fuzzySearch}
            value={selectedHotel?.id.toString()}
            placeholder="Select your hotel"
            multiple={false}
            //@ts-ignore
            onChange={(_, value: ISelectHotelOption) => handleHotelChange(value)}
          />
        </HotelSelectionContainer>
      )}

      {DATE_SELECTION && (
        <WhenContainer>
          <Title>Trip dates</Title>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Check-in"
              endText="Check-out"
              disablePast
              //block more than MAX_NUM_OF_DAYS only if selected start_day only
              // minDate={min_day}
              // maxDate={((startDate && endDate)) ? null : max_day}
              value={dateRange}
              onChange={(newDateRange) => {
                handleDateRangeChange(newDateRange);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <DateInput
                    {...startProps}
                    InputLabelProps={{
                      style: {
                        color: 'lightgray',
                      },
                    }}
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <DateInput
                    {...endProps}
                    InputLabelProps={{
                      style: {
                        color: 'lightgray',
                      },
                    }}
                  />
                </>
              )}
            />
          </LocalizationProvider>
          {!VALID_DATES && (
            <ErrorAlert style={{ marginTop: '1rem' }}>
              {`Please select date range of up to ${MAX_DATES_RANGE} days!`}
            </ErrorAlert>
          )}
        </WhenContainer>
      )}

      <AlreadyHadTripContainer>
        <h4>
          <u>Already created a trip?</u>
        </h4>
        <Description>
          In order to go to your personal trip,
          <br /> please insert your email address & personal code here:
        </Description>
        <StyledCollapse in={!loading}>
          <div>
            <EmailInput
              type="email"
              placeholder="traveler@example.com"
              value={emailValue}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
          </div>
          <div>
            <CodeInput
              type="number"
              placeholder="1234"
              value={codeValue}
              onChange={(e) => handleCodeChange(e.target.value)}
            />
          </div>
        </StyledCollapse>
        {error && (
          <Alert
            variant="standard"
            severity="error"
            style={{ width: 'max-content', margin: 'auto' }}
          >
            {error}
          </Alert>
        )}
        <Button
          loadingSpinner
          spinnerState={loading}
          onClick={handleSendEmail}
          spinnerSize={20}
          text="Go To Trip"
          disabled={!codeValid}
          style={{
            width: '40%',
            minWidth: '12rem',
            color: textColor,
            height: '2.5rem',
            background: bgColor,
            margin: '0.5rem auto',
          }}
        />
        <div
          style={{
            fontSize: '0.8rem',
            verticalAlign: 'middle',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Lost the code? &nbsp;
          {resendLoading ? (
            <CircularProgress size={11} />
          ) : showSent ? (
            <span
              style={{
                color: 'green',
                fontWeight: 'bolder',
                verticalAlign: 'middle',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <CheckIcon
                fontSize="small"
                sx={{
                  color: 'green',
                }}
              />
              sent
            </span>
          ) : (
            <ActionSpan onClick={handleResend}>Resend</ActionSpan>
          )}
        </div>
      </AlreadyHadTripContainer>
      <div style={{ height: '6rem' }}></div>
    </Container>
  );
}
const StyledCollapse = styled(Collapse)`
  && {
    display: flex;
    flex-direction: column;
  }
`;

const ActionSpan = styled.a`
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const Description = styled.div`
  margin: auto;
  max-width: 600px;
  width: 100%;
  margin-bottom: 1rem;
`;

const AlreadyHadTripContainer = styled.div`
  padding: 1rem;
  text-align: center;
  margin: auto;
  margin-bottom: 5rem;
  background-color: rgba(0, 0, 0, 0.09);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
`;
const EmailInput = styled.input`
  border: 2px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  width: 80%;
  height: 2rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  max-width: 20rem;
  & ::placeholder {
    color: lightgray;
  }
`;

const CodeInput = styled.input`
  font-size: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  width: 8rem;
  margin-bottom: 0.5rem;
  height: 2rem;
  text-align: center;
  letter-spacing: 0.3rem;
  & ::placeholder {
    color: lightgray;
  }
  //Hide Input Arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Container = styled('div')<{ url: string }>`
  text-align: left;
  margin: auto;
  max-width: 600px;
  width: 100%;

  /* * Main wrapper */
  .select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /* * Value wrapper */
  .select-search__value {
    position: relative;
    z-index: 1;
  }

  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }

  /* * Input */
  .select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 30px;
    background: #fff;
    border: 1px solid transparent;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
    background-image: url(${(p) => p.url});
    background-repeat: no-repeat;
    background-size: 17px;
    background-position: 7px center;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  /* * Options wrapper */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }

  /* Options */
  .select-search__options {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Option row */

  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /*  Option */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    /* padding: 0 16px; */
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: ${(p) => p.theme.primary.bgColor};
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: ${(p) => p.theme.primary.bgColor};
    color: #fff;
  }

  /* * Group */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /* * States */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  /* * Modifiers */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: ${(p) => p.theme.primary.bgColor};
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
`;

const DateInput = styled(TextField)`
  && {
    width: 50%;
    background-color: white;
    color: #d32e2e;
  }
`;

const Title = styled.div`
  font-weight: 500;
  margin-bottom: 1rem;
`;

const HotelSelectionContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
`;
const WhenContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
`;
