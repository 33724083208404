import { useTripDetails } from "../../providers/TripDetailsProvider";
import styled from "styled-components/macro";
import { HideOnDesktop } from "../shared/Layout";
import { Button } from "@material-ui/core";
import moment from "moment";
import { useFeed } from "../pages/Feed/useFeed";

interface IProps {
  actionIcons: IActionIcons[]
}
export default function FeedTopBar({ actionIcons }: IProps) {
  const { state: { currentDayIndex } } = useFeed()
  const { tripDetails: { tripAccommodation: { city, start_date } } } = useTripDetails()
  let currentDayLabel = moment(start_date).add(currentDayIndex, 'days').format('MMM DD');
  return (
    <Container>

      <Title>{`${currentDayLabel} •  ${city}`} </Title>

      <HideOnDesktop>
        <ActionsIconsContainer >
          {actionIcons?.map(({ icon, onIconClick, text }: IActionIcons, i) =>
            <ActionButton onClick={onIconClick} key={`actionTopBar${i}`}>
              <Img src={icon} alt="action" />
              <ActionTitle>{text}</ActionTitle>
            </ActionButton>)}
        </ActionsIconsContainer>
      </HideOnDesktop>
    </Container>
  )
}

const ActionButton = styled(Button)`
&&{
  padding:0;
  background:transparent;
  color:black;
  display: flex;
  flex-direction: column;
  text-transform: unset;
  /* margin-left:0.5rem; */
}
`

const ActionTitle = styled.span`
  font-size:0.7rem;
`;

const Img = styled.img`
  height: 4vh;
`;

const ActionsIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
   
`;

const Container = styled.div`
  background-color: #ffffffe6;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 10px 10px 0px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;

  margin: 1rem 0;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 350px;
`;