import { Dialog } from '@material-ui/core';
import styled from 'styled-components/macro';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useFeed } from '../Feed/useFeed';
import { useEffect, useRef } from 'react';
import { logTiqetsOrder } from '../../../core/services/tiqets';
import { formatDateto_YYYY_MM_DD_HH_MM_SS } from '../../../core/utils/common';
import { useTripDetails } from '../../../providers/TripDetailsProvider';

export default function IFrameBookingDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    state: { openTiqetsBookingDialog: open, selectedItemBook },
    dispatch,
  } = useFeed();

  const {
    tripDetails: { _id: trip_id },
  } = useTripDetails();

  /** Used as the reference to the state 'open' of the dialog ,
   * otherwise, the setTimeout will refer itself NOT to the real-time state */
  const openRef = useRef(open);
  openRef.current = open;

  const DELAY_SECONDS = 90; //1.5 minutes as a reasonible time to complete a booking flow

  useEffect(() => {
    /**Creates an interval of 2 minutes and triggers a booking log to the server.
     * The server tries to find correlation between the event ant the orders that was made on the same time.
     */
    async function initAnalytics() {
      const analyticsTimer = setTimeout(async () => {
        triggerAnalytics();
      }, 1000 * DELAY_SECONDS);

      return () => clearTimeout(analyticsTimer);
    }
    initAnalytics();
  }, [open]);

  if (!selectedItemBook || !selectedItemBook?.uuid) return null;

  const { uuid, _id, source } = selectedItemBook;
  async function triggerAnalytics() {
    try {
      if (selectedItemBook && selectedItemBook.uuid) {
        const date = new Date();
        const DateStr: string = formatDateto_YYYY_MM_DD_HH_MM_SS(date);

        if (openRef.current) {
          await logTiqetsOrder({
            external_id: uuid,
            trip_id,
            itinerary_item_id: _id,
            window_open_time: DateStr,
            window_close_time: DateStr,
          });
        }
      }
    } catch (error) {}
  }

  function handleClose(): void {
    dispatch({ type: 'TOGGLE_TIQETS_DIALOG' });
  }

  const productURL: string =
    source === 'Tiqets'
      ? `https://www.tiqets.com/widgets/booking_engine/checkout/${uuid}?partner=bridgify`
      : `XXXXXX_TicketMaster_XXXXXXXXX`;

  return (
    <Dialog open={open} maxWidth="xl" fullScreen={fullScreen}>
      <Container>
        <XButtonOverlay onClick={handleClose} />
        <IFrame src={productURL} />
      </Container>
    </Dialog>
  );
}

//Is on top of the X button in order to close the dialog
// Because we can't control the dialog inside of the iframe
const XButtonOverlay = styled.div`
  position: absolute;
  width: 13vw; // fits to the desktop
  height: 5rem;
  right: 0;
  top: 0;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 5rem;
    height: 4rem;
  }
`;

const Container = styled.div`
  width: 70vw;
  height: 90vh;
  background-color: white;

  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh;
  }
`;

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
