import React from "react";
import { CircularProgress, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import styled, { CSSProperties } from "styled-components";
interface IButton {
  text?: string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loadingSpinner?: boolean;
  spinnerState?: boolean;
  background?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> | null | string;
  textColor?: string;
  style?: CSSProperties;
  spinnerSize?: number;
  children?: any;
}

export default function Button({
  text, Icon, onClick, background,
  disabled = false,
  loadingSpinner = false,
  spinnerState = false, style,
  textColor, spinnerSize, children
}: IButton) {
  const solidBoarder: boolean = text === 'Navigate'
  return (
    <StyledButton
      style={style}
      disabled={(disabled || spinnerState)}
      background={background}
      solidBoarder={solidBoarder}
      textColor={textColor}
      onClick={onClick}>
      {Icon && <>
        {typeof Icon == "string" ?
          <img src={Icon} alt={text} /> :
          (Icon && <Icon />)}
        &ensp;</>}

      {(loadingSpinner && spinnerState) && <StyledCircularProgress size={spinnerSize} />}
      {text}{children}
    </StyledButton>
  );
};

const StyledButton = styled('button') <{ background?: string, solidBoarder: boolean, textColor?: string }>`
  &&{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  width: 80%;
  max-width: 350px;
  height: 50px;
  font-size: 1.1rem;
  border: ${p => p.solidBoarder ? '1px solid grey' : ''};
  background: ${p => p.background};
  color: ${p => p.textColor};
}

  &:disabled {
    color: white;
    /* background: #dedede; */
    opacity: 0.7;
    box-shadow: none;
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
&&{
  position: absolute;
  left:15px;
  top:5px;
  /* width: 5px;
  height: 5px; */
  margin-right: 0.5rem;
  color:white;
}
`