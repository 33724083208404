import styled from "styled-components/macro";
export interface IRank {
  value?: number;
  count?: number;
}

// function numberWithCommas(value: number): string {
//   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export default function Rank({ count, value }: IRank) {

  const NO_REVIEWS: boolean = (!count || count === 0 || !value || value === 0)
  const ReviewsLabel = (
    <RankContainer>
      {value && <Star >★ {value}</Star>}
        &nbsp;
      {/* {count && <Count>
        ({numberWithCommas(count)})
      </Count>} */}
    </RankContainer>
  )

  //const NoReviewsLabel = <div> no reviews</div>

  return NO_REVIEWS ? null : ReviewsLabel

}

const RankContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size:1rem;
  line-height:17px;
  color:#0E1738;
`;

// const Count = styled.div`
//   color: #97A0B1;
// `;
const Star = styled.div`
  font-size:1rem;
  font-weight: bold;
`;