export enum Endpoints {
  createTrip = '/create-trip/',
  deleteEvent = '/delete-event/',
  tripDetails = '/trip-details/',
  addEvent = '/add-event/',
  dayItinerary = '/day-itinerary/',
  moreAttractions = '/more-attractions/',
  clientDesign = '/get-client-design/',
  setAttractionStatus = '/set-attraction-status/',
  sendVerificationEmail = '/send-verification-email/',
  verifycode = '/verify-code-from-email/',
  getUserToken = '/get-user-token/',
  getUserTokenWithMail = '/get-user-token-with-mail/',
  addItemFavorites = '/add-item-favorite/',
  removeItemFavorites = '/remove-item-favorite/',
  getFavoriteItems = '/get-favorite-items/',
  logTiqetsOrder = '/log-tiqets-order/',
}
export enum Routes {
  feed = '/feed',
  mapView = '/map-view',
  attractionId = '/attraction/:id',
  explore = '/explore',
  onBoarding = '/onboarding',
  default = '/',
  attraction = '/attraction/',
  page404 = 'error404',
}
