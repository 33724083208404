import styled, { CSSProperties } from 'styled-components';
import Spinner from './Spinner/Spinner';
import LoaderWithImage from './LoaderWithImage/LoaderWithImage';
import WelcomePage from '../pages/WelcomePage';
import { LoaderTypes } from '../../core/utils/enums';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import Button from './Button';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import FooterTitles from './FooterTitles';

interface IAppBarProps {
  title: string;
  subtitle?: string;
  handleBack?: () => void;
  backOrClose?: 'BACK' | 'CLOSE' | undefined;
  actionIcons?: IActionIcons[]; // TODO - icons interface
}
interface IFooter {
  position?: 'fixed' | 'relative';
  button?: {
    text: string;
    disabled: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
}
interface IProps {
  loading?: boolean;
  loaderType: LoaderTypes;
  children?: any;
  appbar?: IAppBarProps;
  footer?: IFooter;
  style?: CSSProperties;
}

const LoaderDic: { [key in LoaderTypes]: any } = {
  [LoaderTypes.Dots]: Spinner,
  [LoaderTypes.Images]: LoaderWithImage,
  [LoaderTypes.WelcomePage]: WelcomePage,
};

export default function Page({
  children,
  loading = false,
  loaderType,
  appbar,
  footer,
  style,
}: IProps) {
  const {
    tripDetails: {
      ClientDesign: {
        PrimaryColor: { bgColor, textColor },
      },
    },
  } = useTripDetails();
  const LOADER_TESTING: boolean = false;

  loading = LOADER_TESTING ? !loading : loading;

  const RenderedLoader = LoaderDic[loaderType];

  const LoaderComponent = <RenderedLoader>Please wait while we fetch data for you</RenderedLoader>;

  return loading ? (
    LoaderComponent
  ) : (
    <Container>
      {appbar && (
        <StyledAppBar>
          <Toolbar>
            {appbar?.backOrClose && (
              <IconButton edge="start" onClick={appbar.handleBack}>
                {appbar.backOrClose === 'BACK' ? <BackIcon /> : <CloseIcon />}
              </IconButton>
            )}
            <AppBarContent>
              <Titles>
                <Title>{appbar.title}</Title>
                {appbar.subtitle && <SubTitle>{appbar.subtitle}</SubTitle>}
              </Titles>

              {/* <Hidden smUp>
              <ActionsIconsContainer >
                  {appbar?.actionIcons
                    ?.map(({ icon, onIconClick, text }: IActionIcons, i) =>
                    <MapSideButton onClick={onIconClick}>
                      <Img src={icon} alt="action" />
                      <ActionTitle>{text}</ActionTitle>
                    </MapSideButton>)}
              </ActionsIconsContainer>
              </Hidden> */}
            </AppBarContent>
          </Toolbar>
        </StyledAppBar>
      )}

      <PageContent style={style}>{children}</PageContent>

      <FooterContainer position={footer?.position}>
        {footer?.button && (
          <Button
            disabled={footer?.button?.disabled}
            onClick={footer.button?.onClick}
            style={{ backgroundColor: bgColor, color: textColor, margin: '0.5rem 0' }}
          >
            {footer?.button?.text}
          </Button>
        )}
        <FooterTitles />
      </FooterContainer>
    </Container>
  );
}

const FooterContainer = styled('div')<{ position?: 'fixed' | 'relative' }>`
  /* grid-row: 3/3; */
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(14, 23, 56, 0.08);
  /* backdrop-filter: blur(4px); */
  position: fixed;
  bottom: 0;
  /* position:  ${(p) => p.position || 'fixed'}; */
  flex-direction: column;
  align-items: center;
  z-index: 3;
`;

const Titles = styled.div``;

const Title = styled.div``;

const Container = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-flow: column;
  /* overflow-y: hidden; */
  /* box-sizing:content-box; */
  /* display:grid; */
  /* grid-template-rows: 10vh 85vh 5vh; */
`;

const PageContent = styled.div`
  padding: 1rem;
  height: 100%;
  flex: 1 0 auto;
  /* grid-row: 2/2; */
`;

const AppBarContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
`;

const StyledAppBar = styled(AppBar)`
  && {
    position: sticky;
    background-color: #ffffff;
    /* grid-row: 1/1; */
  }
`;
