import { Hidden } from "@material-ui/core";

export function HideOnDesktop({ children }): JSX.Element {
  return (
    <Hidden smUp >
      {children}
    </Hidden>
  )
}
export function ShowOnDesktop({ children }): JSX.Element {
  return (
    <Hidden smDown>
      {children}
    </Hidden>
  )
}