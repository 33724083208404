import {
  //  Button, CircularProgress, DialogActions,
  LinearProgress
} from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  //  cancelOrderOfItem,  setMusementActivityStatus,
  getCancellationPolicyForOrder, getOrderDetails,
} from "../../../core/services/musement";
import { TimeUnitsDic } from "../../../core/utils/dictionaries";
import { ErrorAlert } from "../../shared/Alerts";
import Modal from "../../shared/Modal";

import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

// const TEST = {
//   "item_id": 53337,
//   "order_id": "28450a6c-e615-49af-a5d6-e0cc5f6ea36c",
//   "status": "BOOKED",
//   "pickup": {
//     "name": "H222: Buena Vista Motor Inn - 1599 Lombard St",
//     "uuid": "da77b427-e5b0-403b-86c3-75467369b502",
//     "lng": 0, "lat": 0
//   },
//   "activity_datetime": "2021-08-03 07:30"
// }
interface IPolicy_converted {
  periodLabel: string;
  DATE_PASSED: boolean;
  value: number;
  applicable_until: string;
  remaining_time: string;
}

interface IProps {
  handleCloseModal: (event: any) => void;
  showModal: boolean;
  item: ITripItem_Attraction;
}
export default function CancellationModal({
  handleCloseModal, showModal,
  item: { name,
    uuid, orderUuid,
    // uuid = '15bd163e-2046-11e7-9cc9-06a7e332783f',
    // orderUuid = '28450a6c-e615-49af-a5d6-e0cc5f6ea36c',
  }
}: IProps) {
  // const [policies, setPolicies] = useState<ICancellationRemainingTime[]>()
  const [itemsUUIDArray, setItemsUUIDArray] = useState<string[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [policies, setPolicies] = useState<IPolicy_converted[]>()
  const [bottomLinePolicy, setBottomLinePolicy] = useState<number>(-1)
  // const [cancelLoading, setCancelLoading] = useState<boolean>(false)

  useEffect(() => {
    /**
   * Creates an array of the item's UUID's
   * @param items user's cart items
   */
    function createItemsUUIDArray(items: IOrderItemDetails[]): string[] {
      let resultArray: string[] = [];
      items.forEach((item: IOrderItemDetails) => resultArray.push(item.uuid))
      return resultArray;
    }


    function convertPendingFormat(timeUnitLetter: 'H' | "M" | "D", str: string): string | null {
      const letterIndex: number = str.indexOf(timeUnitLetter);
      if (letterIndex === -1) return '';

      let IndexWithValidDigit: number = letterIndex;
      while (IndexWithValidDigit > 0) {
        const digit: number = +(str[IndexWithValidDigit - 1])
        if (isNaN(digit)) { break; }

        IndexWithValidDigit--;
      }

      const fullNumber: number = +(str.slice(IndexWithValidDigit, letterIndex))
      return !isNaN(fullNumber) && fullNumber > 0 ? `
      ${fullNumber} 
      ${TimeUnitsDic[timeUnitLetter]}${fullNumber > 1 ? 's' : ''}
      `: null
    }
    function generatePeriodLabel(period: string): string | null {
      let labelsStack: string[] = [];
      const days: string | null = convertPendingFormat('D', period);
      const hours: string | null = convertPendingFormat('H', period)
      const minutes: string | null = convertPendingFormat('M', period)
      if (days) labelsStack.push(days)
      if (hours) labelsStack.push(hours)
      if (minutes) labelsStack.push(minutes)
      if (labelsStack.length === 0) return null;

      const periodLabel: string = labelsStack.join(", ")
      return periodLabel;
    }

    async function fetchCancellationPolicy() {
      try {
        setLoading(true)
        if (!uuid || !orderUuid) throw new Error("no uuid was found");
        //A1. get order Details
        const { items } = await getOrderDetails(orderUuid)
        // const items: IOrderItemDetails[] = orderDetails.items
        //A2. get the item's uuid's array 
        const itemsUUIDArray = createItemsUUIDArray(items);
        //A3. (save as state) 
        setItemsUUIDArray(itemsUUIDArray);
        if (!itemsUUIDArray || itemsUUIDArray.length === 0) throw new Error("no items was found");
        //A3. get the cancellation details with the first item-uuid
        const policySample: ICancellationRemainingTime[] = await getCancellationPolicyForOrder(uuid, itemsUUIDArray[0])
        // const policySample: ICancellationRemainingTime[] = await getCancellationPolicyForOrder(uuid, itemuuid)
        //A4. display it for to the user
        if (!policySample || policySample.length === 0) throw new Error("no policies found");

        let policies: IPolicy_converted[] = []
        policySample?.forEach((policy, index) => {
          const { value, period, applicable_until, remaining_time } = policy
          const DATE_PASSED: boolean = Date.parse(applicable_until) < Date.now()
          const periodLabel: string | null = generatePeriodLabel(period)
          const NO_REFUND: boolean = ((period === "P0DT0H0M")) || !periodLabel
          if (!NO_REFUND && periodLabel) {
            policies.push({ periodLabel, DATE_PASSED, value, applicable_until, remaining_time })
          }
        })
        setPolicies(policies)
        const bottomLineIndex: number | null = findIndexOfNotExpiredCancellationPolicy()
        if (bottomLineIndex !== null) {
          setBottomLinePolicy(policySample[bottomLineIndex].value)
        }
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchCancellationPolicy()
  }, [policies?.length])

  /**
   * @param checkedDateStr 
   * @returns true if the date hasn't passed
   */
  function remainingTimeLeft(checkedDateStr: ICancellationRemainingTime["applicable_until"]): boolean {
    if (!checkedDateStr) return false;
    const today: Date = new Date()
    const checkedDate: Date = new Date(checkedDateStr)
    return today < checkedDate
  }

  /**
   * @param policies :ICancellationRemainingTime[]
   * @returns index of not expired policy
   */
  function findIndexOfNotExpiredCancellationPolicy(): number | null {
    let index: number | null = null;
    policies?.forEach((policy, i: number) => {
      const { applicable_until } = policy
      if (remainingTimeLeft(applicable_until)) {
        index = i;
        return;
      }
    });
    return index;
  }

  /** Goes over the order's items (itemsUUIDArray) and cancel them*/
  // async function cancelAllOrderItems(): Promise<void> {
  //   if (!orderUuid || !itemsUUIDArray) throw new Error("no order details");
  //   await Promise.all(itemsUUIDArray?.map(itemUuid => cancelOrderOfItem(orderUuid, itemUuid)))
  // }

  /**
   * cancel all items orders of an order
   * gets the order item's uuid list from the state
   */
  // async function handleCancelOrder() {
  //   try {
  //     if (!orderUuid || !itemsUUIDArray) throw new Error("no order details");
  //     setCancelLoading(true)
  //     await cancelAllOrderItems()
  //     await setMusementActivityStatus({
  //       item_id: _id,
  //       status: "CANCELLED",
  //       order_id: orderUuid
  //     })
  //   } catch (error) {
  //     setError(true)
  //   } finally {
  //     setCancelLoading(false)
  //   }
  // }


  const PoliciesMap = () =>
    <Table>
      {policies?.map((label, index) => {
        const { DATE_PASSED, periodLabel, value } = label
        return <><TableRow>
          {DATE_PASSED ? <StyledClose /> : <StyledCheck />}
          <Policy DATE_PASSED={DATE_PASSED} last={index === policies.length - 1} >
            Cancel <b> {periodLabel}</b> before and get a <b>{value}%</b> refund
          </Policy>
        </TableRow>
        </>
      })}
    </Table>

  const linkToMusementMail = (
    <Alink href="mailto: support@musement.com" target="_top">
      support@musement.com
    </Alink>
  )
  const NO_REFUND: boolean = bottomLinePolicy < 1;
  return (
    <Modal
      handleBack={handleCloseModal}
      open={showModal}
      title={'Activity\'s Cancellation Policy '}
      subtitle={name}>
      {loading ? <LinearProgress /> : error ?
        <ErrorAlert>
          OOPS! We had trouble with cancelling your order, please contact {linkToMusementMail}
        </ErrorAlert>
        : <>
          <PoliciesMap />
          <BottomLine>
            To cancel your order
            {NO_REFUND ? ' without receiving a' : `and receive a ${bottomLinePolicy}%`} refund,
            contact {linkToMusementMail}.
          </BottomLine >
          {/* <ButtonsContainer >
            <ContainedButton onClick={handleCancelOrder} color="primary" variant="contained" disabled={cancelLoading}>
              {cancelLoading ? <Loader size={25} /> : 'Confirm'}
            </ContainedButton>
            <OutlinedButton onClick={handleCloseModal} color="primary" variant="outlined">
              Back
            </OutlinedButton>
          </ButtonsContainer> */}
        </>}
    </Modal>
  )
}
// const Loader = styled(CircularProgress)`
// &&{
//   color:white;
// }
// `;

const Table = styled.table`
  /* border:1px solid #ddd8d8;
  border-radius: 5px; */
  margin-bottom: 5px;
  margin-top: 1rem;
`;

const TableRow = styled.tr`
  margin:0;
  display: flex;
    flex-direction: row;
    align-items: center;
`;


const StyledClose = styled(CloseIcon)`
&&{
  color:#d32f2fa1;
}
`;

const StyledCheck = styled(CheckIcon)`
&&{
  color:#2e7d32a6;  
}
`;

// const ButtonsContainer = styled(DialogActions)`
// &&{
//   place-content: space-evenly;
//   margin-top:1rem;
// }
// `;

const BottomLine = styled.div`
  margin: 1rem 0 ;
  font-size: 0.8rem;
`;

const Policy = styled('td') <{ DATE_PASSED?: boolean, last: boolean }>`
  font-size: 0.8rem;
  padding:5px;
  /* text-decoration: ${p => p.DATE_PASSED ? 'line-through' : ''} ; */
  color: ${p => p.DATE_PASSED ? '#a5a5a5' : ''} ;
  /* border-bottom: ${p => p.last ? 'none' : '1px solid #ddd8d8'}; */

`;

const Alink = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: darkblue;
`;
// const ContainedButton = styled(Button)`
// &&{
//   background-color: #0e1738;
//   min-width: 40%;
//   font-weight: 400;
//   :disabled{
//     background-color: #0e1738;
//     opacity:0.7;
//   }
// }
// `;

// const OutlinedButton = styled(Button)`
// &&{
//   min-width: 40%;
//   color: #0e1738;
//   border: 1px solid #0e1738;
//   font-weight: 500;
// }
// `;



