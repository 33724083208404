import { TRIP_ITEMS } from "../../../../core/utils/enums";
import styled from "styled-components/macro";
import { Icons } from "../../../../assets/images/images";

interface IProps {
    attractionType: TRIP_ITEMS;
    lat: number;
    lng: number;
    index: number;
}
export default function Marker({ index }: IProps) {
    return (
        <Container>
            <AbsoluteContainer>
            <TimelineIndex>{index}</TimelineIndex>
            <img src={Icons.pin} alt="pin" />
            </AbsoluteContainer>
            {/* <div className={[Style.pin, Style.bounce].join(' ')}>
                <span className={Style.icon}>
                </span>
                <hr />

                <span className={Style.markerText}
                    style={{
                        background: PrimaryColor.bgColor,
                        color: PrimaryColor.textColor
                    }}>
                    {index + 1}
                </span>
            </div> */}
            {/* {zoom === 15 ? <div className={Style.pulse} /> : null} */}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;
const AbsoluteContainer = styled.div`
    position: absolute;
    top: -50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const TimelineIndex = styled.div`
  background-color: ${p => p.theme.primary.bgColor};
  color: ${p => p.theme.primary.textColor};
  padding:5px 7px;
  font-weight:600;
  font-family: Roboto;
  border-radius: 5px;
  margin-bottom: 5px;
`;
