import mixpanel from 'mixpanel-browser';
import ParamsService from './params';
import StorageService from './storage';

//if the test flag is in the URL it won't trigger the analytics.
const TEST_DISABLING_FLAG: boolean = StorageService.local.get.TEST() || ParamsService.getTEST();

//Analytics will be triggered on production or if there's a 'REACT_APP_TRIGGER_ANALYTICS' flag in the .env file
const TRIGGER_ANALYTICS_CONF: boolean =
  process.env.NODE_ENV === 'production' || process.env.REACT_APP_TRIGGER_ANALYTICS !== undefined;

/** Trigger Analytics only if there's no test flag and if on production/.env file  */
const TRIGGER_ANALYTICS = !TEST_DISABLING_FLAG && TRIGGER_ANALYTICS_CONF;

export enum MP_Pages {
  Feed = 'Feed',
  InfoPage = 'InfoPage',
  Explore = 'Explore',
}

export enum MP_Events {
  AppOpen = 'App Open',
  DeleteItem = 'Delete Item',
  CreatedTrip = 'Created Trip',
  Onboarding = 'Onboarding',

  Explore = 'Explore',
  MapIcon = 'Map-Icon',
  ItemCard = 'Item-Card',

  Navigate = 'Navigate',
  BookNow = 'Book-Now',
  AddToTrip = 'Add-To-Trip',
}

export enum MP_Props {
  firstTime = 'first time',
  tripCreated = 'trip created',
  client = 'client',
  pace = 'pace',
  interests = 'interests',
  lastScreen = 'last screen',
  status = 'status',
  completed = 'completed',
  uncompleted = 'uncompleted',
  tokenless = 'tokenless',
  redirected = 'redirected',
  Desktop = 'Desktop',
}

const Mixpanel = {
  initAndIdentify: () => {
    const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (TRIGGER_ANALYTICS && MIXPANEL_TOKEN)
      mixpanel.init(MIXPANEL_TOKEN, {
        loaded: () => {
          const cachedToken: string | null = StorageService.local.get.token();
          const distinctID = mixpanel.get_distinct_id();
          const currentID = cachedToken || distinctID;
          Mixpanel.identify(currentID);
        },
      });
  },
  identify: (userID?: string) => {
    if (TRIGGER_ANALYTICS) mixpanel.identify(userID);
  },
  alias: (new_id: string, existing_id: string) => {
    if (TRIGGER_ANALYTICS) mixpanel.alias(new_id, existing_id);
  },
  track: (name: MP_Events, props: Object = {}) => {
    if (TRIGGER_ANALYTICS) mixpanel.track(name, props);
  },
  getDistinctID: () => {
    if (TRIGGER_ANALYTICS) return mixpanel.get_distinct_id();
  },
  register: (props: Object = {}) => {
    if (TRIGGER_ANALYTICS) return mixpanel.register(props);
  },
  people: {
    set: (props: any) => {
      if (TRIGGER_ANALYTICS) mixpanel.people.set(props);
    },
  },
};

export default Mixpanel;
