import WelcomePage from '../WelcomePage';
import TripPlanningPage from './TripPlanningPage/TripPlanningPage';
import TripTypesPage from './TripTypesPage';
import InterestsPage from './InterestsPage';
import StayDetailsPage from './StayDetailsPage';
import Mixpanel, { MP_Events, MP_Props } from '../../../core/services/mixpanel';
import { createTrip } from '../../../core/services/trip';
import { OnBoardingStates, TOAST_TYPES, TRIP_TYPES } from '../../../core/utils/enums';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { OnBoardingStatesDic, TripTypesDic } from '../../../core/utils/dictionaries';
import { useReducer } from 'react';
import { convetSetOfInterestIntoArray } from '../../../core/utils/common';
import { Routes } from '../../../core/utils/endpoints';
import moment from 'moment';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import StorageService from '../../../core/services/storage';

interface IPage {
  NextPage: OnBoardingStates;
  component: any;
  title?: string;
  subtitle?: string;
}

const onBoardingPropertiesMapper: { [key in OnBoardingStates]: IPage } = {
  [OnBoardingStates.WelcomePage]: {
    component: WelcomePage,
    NextPage: OnBoardingStates.TripTypesPage,
  },
  [OnBoardingStates.TripPlanningPage]: {
    component: TripPlanningPage,
    NextPage: OnBoardingStates.TripTypesPage,
    title: 'Plan Your Trip',
  },
  [OnBoardingStates.StayDetailsPage]: {
    component: StayDetailsPage,
    NextPage: OnBoardingStates.TripTypesPage,
    title: 'Plan Your Trip',
  },
  [OnBoardingStates.TripTypesPage]: {
    component: TripTypesPage,
    NextPage: OnBoardingStates.InterestsPage,
    title: 'The Intensity',
  },
  [OnBoardingStates.InterestsPage]: {
    component: InterestsPage,
    NextPage: OnBoardingStates.InterestsPage, //not really in use
    title: 'Your Interests',
  },
};

export type TOnboardingActions = {
  type:
    | 'NEXT'
    | 'INTEREST'
    | 'BACK'
    | 'SELECTED_PLAN'
    | 'TRIP_TYPE'
    | 'TOGGLE_LOADER'
    | 'HOTEL_NAME'
    | 'DATE_RANGE';
  payload?: any;
};

export interface IOnBoardingState {
  mode: OnBoardingStates;
  selected_type?: TRIP_TYPES;
  selected_interests: Set<number>;
  selected_plan: number | null;
  loading: boolean;
  selectedHotel?: IHotel;
  dateRange: [Date | null, Date | null];
}

export function useOnboarding(): IUseOnboarding {
  const {
    setTripDetailsWrapper,
    tripDetails: {
      ClientDesign: { DATE_SELECTION, HOTEL_SELECTION, TOKENLESS_ONBOARDING, name: clientName },
    },
  } = useTripDetails();
  const history = useHistory();
  const { addToast } = useToasts();

  const initialOnBoardingState: IOnBoardingState = {
    // mode: OnBoardingStates.StayDetailsPage,
    mode: TOKENLESS_ONBOARDING ? OnBoardingStates.StayDetailsPage : OnBoardingStates.TripTypesPage,
    selected_interests: new Set(),
    selected_plan: null,
    loading: false,
    dateRange: [null, null],
  };
  function reducer(state: IOnBoardingState, action: TOnboardingActions): IOnBoardingState {
    const { mode, loading, selected_interests } = state;
    const { payload, type } = action;
    switch (type) {
      case 'INTEREST': {
        //handling interests choosing
        const interests = new Set(selected_interests);
        interests.has(payload) ? interests.delete(payload) : interests.add(payload);
        return { ...state, selected_interests: interests };
      }
      case 'SELECTED_PLAN':
        return { ...state, selected_plan: payload.selectedTripPlan };
      case 'NEXT':
        return { ...state, mode: onBoardingPropertiesMapper[mode].NextPage };
      case 'BACK':
        return { ...state, mode: mode - 1 };
      case 'TOGGLE_LOADER':
        return { ...state, loading: !loading };
      case 'HOTEL_NAME':
        return { ...state, selectedHotel: payload };
      case 'DATE_RANGE':
        return { ...state, dateRange: payload };
      case 'TRIP_TYPE':
        return { ...state, selected_type: payload };
      default:
        return { ...state };
    }
  }

  const [state, dispatch] = useReducer(reducer, initialOnBoardingState);
  const { selected_plan, mode, selected_interests, selected_type, selectedHotel, dateRange } =
    state;

  const [startDate, endDate] = dateRange;

  /**
   * Create the create-trip call payload
   * for the create-trip call:
   * @params intensity, interests[]
   *
   * for tokenless onboarding we attach fields according to the following indicators:
   * @indicators CITY_SELECTION, DATE_SELECTION, HOTEL_SELECTION, TOKENLESS_ONBOARDING
   * @params startDate , endDate, hotelName
   */
  async function createTripHandler() {
    const tripPrefrences: ITripPreferences = {
      intensity: selected_type || 0,
      interests: Array.from(selected_interests.values()),
    };
    // const tripDetails: ITripDetailsRequest = {
    //   number_of_days: number_of_days,
    //   start_date: start_date,
    // };

    let payload: ICreateTripPayload | undefined = {
      tp: tripPrefrences,
      tokenless: TOKENLESS_ONBOARDING,
      client: clientName,
    };
    let tripDetails: ITripDetailsRequest | undefined = undefined;
    //if it's a TOKENLESS_ONBOARDING--> check for hotel + date details and a 'td' to the payload
    if (TOKENLESS_ONBOARDING) {
      tripDetails = {};

      if (DATE_SELECTION) {
        if (!(startDate && endDate)) throw new Error('no dates were choosen');

        var start = moment(startDate);
        var end = moment(endDate);
        tripDetails.start_date = startDate.toISOString().slice(0, 10);
        tripDetails.number_of_days = end.diff(start, 'days') + 1;
      }

      if (HOTEL_SELECTION) {
        if (!selectedHotel) throw new Error('no dates were choosen');
        payload.hotel_id = selectedHotel.id;
      }

      payload.td = tripDetails;
    }

    console.time('create Trip timer');

    const createdTrip = await createTrip(payload, TOKENLESS_ONBOARDING);

    console.timeEnd('create Trip timer');
    return createdTrip;
  }

  async function finishTrip() {
    const analyticsProps = {
      [MP_Props.pace]: selected_type !== undefined ? TripTypesDic[selected_type] : '',
      // [MP_Props.pace]: TripTypesDic[state?.selected_type || TRIP_TYPES.Moderate],
      [MP_Props.interests]: convetSetOfInterestIntoArray(selected_interests).join(', '),
      [MP_Props.status]: MP_Props.completed,
      [MP_Props.client]: clientName,
      [MP_Props.redirected]: StorageService.local.get.redirectionSource() || undefined,
    };
    Mixpanel.track(MP_Events.Onboarding, analyticsProps);

    toggleLoader();
    try {
      const createdTripResponse: ITripDetails_Response = await createTripHandler();
      setTripDetailsWrapper(createdTripResponse, false);
      const { token } = createdTripResponse;
      if (TOKENLESS_ONBOARDING && token) {
        StorageService.local.save.token(token);
        //Merging the two different tokens together into one Alias
        Mixpanel.alias(Mixpanel.getDistinctID(), token);
      }
      // Mixpanel.track(MP_Events.CreatedTrip, { status: 'succeed' })
      history.push(Routes.feed);
    } catch (error) {
      // Mixpanel.track(MP_Events.CreatedTrip, { status: 'failed' })
      addToast('OOPS! We had trouble creating your trip!', { appearance: TOAST_TYPES.Error });
      // console.error('OOPS! We had trouble creating your trip!', error)
    }
  }

  const MIN_NUMBERS_OF_INTERESTS = 5;
  const MAX_NUMBERS_OF_INTERESTS = 10;

  //This function indicates whether the user is able to continue (chose enough interests/ filled all fields, Etc)
  function canContinue(): boolean {
    switch (mode) {
      case OnBoardingStates.TripTypesPage:
        return selected_type !== undefined;
      case OnBoardingStates.InterestsPage:
        return (
          selected_interests.size >= MIN_NUMBERS_OF_INTERESTS &&
          selected_interests.size <= MAX_NUMBERS_OF_INTERESTS
        );
      case OnBoardingStates.TripPlanningPage:
        return startDate !== null && selected_plan !== null;
      case OnBoardingStates.StayDetailsPage:
        //no date/hotel selection or if there's it's not empty
        if (DATE_SELECTION && startDate && endDate) {
          var start = moment(startDate);
          var end = moment(endDate);
          const numOfDays: number = end.diff(start, 'days') + 1;
          if (numOfDays > 10 || numOfDays < 1) {
            return false;
          }
        }
        return (
          (!DATE_SELECTION || (startDate !== null && endDate !== null)) &&
          (!HOTEL_SELECTION || selectedHotel !== undefined)
        );
      default:
        return false;
    }
  }

  function nextButtonHandler() {
    if (OnBoardingStates.InterestsPage === mode) {
      finishTrip();
    } else {
      dispatch({ type: 'NEXT' });
    }
  }

  function handleBack(): void {
    dispatch({ type: 'BACK' });
  }

  function toggleLoader(): void {
    dispatch({ type: 'TOGGLE_LOADER' });
  }

  function handleLeavingPage(event) {
    const analyticsProps = {
      [MP_Props.lastScreen]: OnBoardingStatesDic[mode || OnBoardingStates.WelcomePage],
      [MP_Props.status]: MP_Props.uncompleted,
      [MP_Props.client]: clientName,
    };

    if (selected_type !== undefined) {
      analyticsProps[MP_Props.pace] = TripTypesDic[selected_type];
    }
    if (selected_interests.size > 0) {
      analyticsProps[MP_Props.interests] =
        convetSetOfInterestIntoArray(selected_interests).join(', ');
    }

    Mixpanel.track(MP_Events.Onboarding, analyticsProps);
    event.preventDefault();
  }

  const canContinueIndicator: boolean = canContinue();
  const RenderedPage = onBoardingPropertiesMapper[mode];
  return {
    canContinue: canContinueIndicator,
    RenderedPage,
    handleLeavingPage,
    nextButtonHandler,
    handleBack,
    state,
    dispatch,
  };
}

interface IUseOnboarding {
  canContinue: boolean;
  RenderedPage: IPage;
  handleLeavingPage: (e: any) => void;
  nextButtonHandler: () => void;
  handleBack: () => void;
  state: IOnBoardingState;
  dispatch: React.Dispatch<TOnboardingActions>;
}
