import React, { useState } from "react";
import Style from "./TripPlans.module.scss";

import FamilyIcon from '../../../../assets/images/tripPlans/Family.svg';
import SingleIcon from '../../../../assets/images/tripPlans/Single.svg';
import CoupleIcon from '../../../../assets/images/tripPlans/Couple.svg';
import GroupIcon from '../../../../assets/images/tripPlans/Group.svg';

type TTripPlan = typeof TripPlansData[0];

export const TripPlansData = [
    {
        "id": 0,
        "title": "Family",
        "img": FamilyIcon
    },
    {
        "id": 1,
        "title": "Couple",
        "img": CoupleIcon
    },
    {
        "id": 2,
        "title": "Group",
        "img": GroupIcon
    },
    {
        "id": 3,
        "title": "Single",
        "img": SingleIcon
    }
]

interface IProps {
    changePlanHandler: (index: number) => void;
    selectedPlan: number | null;
}
const TripPlans = ({ changePlanHandler, selectedPlan }: IProps) => {

    const onClickHandler = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
        setSelected(index);
        changePlanHandler(index);
    }
    const [selected, setSelected] = useState<number | null>(selectedPlan)
    return (
        <div>
            <div className={Style.interestsContainer}>
                {TripPlansData.map((plan: TTripPlan, index: number) => {
                    const { id, title, img } = plan
                    return (<div key={id} id={`${id}`}
                        onClick={(e) => onClickHandler(e, index)}
                        className={Style.interest}
                        data-testid="interest"
                    // t-current={selected === index}
                    >
                        <div className={Style.imgContainer}>
                            <div className={Style.imgBorder}>
                                <img src={img} alt="" className={Style.planImage} />
                            </div>
                            <div
                                className={Style.selectOverlay}
                                style={selected === index ? { opacity: 1 } : {}}                            >
                                <div className={Style.check}>
                                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23.6833 7.05832L9.91668 20.825L4.31668 15.225L2.68335 16.9167L9.91668 24.0917L25.3167 8.69165L23.6833 7.05832Z" fill="#43A047" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className={Style.interestTitle}>{title}</div>
                    </div>)
                })}
            </div >
        </div>
    );
}

export default TripPlans