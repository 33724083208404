import { BRIDGIFY_DEFAULT_JSON } from '../../providers/TripDetailsProvider';
import { CardBorderColors, TRIP_ITEMS } from '../utils/enums';

const LOAD_IMAGES: boolean =
  process.env.NODE_ENV === 'production' || process.env.REACT_APP_LOAD_IMAGES !== undefined;

export function convertTripDay(day: IDayItinerary): ITripDay {
  const convertedTripItems = day.itinerary_item.map((item) => {
    switch (item.type) {
      case TRIP_ITEMS.Attraction:
        //@ts-ignore
        return convertAttraction(item);
      case TRIP_ITEMS.Restaurant:
        //@ts-ignore
        return convertResturant(item);
      case TRIP_ITEMS.Commute:
        //@ts-ignore
        return convertCommute(item);
      default:
        return {};
    }
  });
  return {
    _id: day.id,
    date: day.date,
    //@ts-ignore
    items: convertedTripItems,
  };
}
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function convertTripDetails(response: ITripDetails_Response): ITripDetails_Converted {
  const { td, tp, day_itinerary, id, design, trip_favorites } = response;
  const { start_date, number_of_days } = td;
  const daysArray = day_itinerary
    .sort((dayA, dayB) => dayA.index - dayB.index)
    .map((day: IDayItinerary) => convertTripDay(day));

  const sortedTripPreferences = {
    ...tp,
    interests: tp.interests.sort((a, b) => a - b),
  };

  // const startDate_Formatted = new Date(start_date || "2022-04-04")
  //   .toISOString()
  //   .slice(0, 10);
  const lastDate_Formatted = addDays(start_date, number_of_days).toISOString().slice(0, 10);

  const tripAccommodationWithFinishDate = {
    ...td,
    finish_date: lastDate_Formatted,
  };

  const convertedClientDesign: IClientDesign = design
    ? convertClientDesign(design)
    : BRIDGIFY_DEFAULT_JSON;
  const convertedTripDetails: ITripDetails_Converted = {
    _id: id,
    tripAccommodation: tripAccommodationWithFinishDate,
    tripPreferences: sortedTripPreferences,
    tripDays: daysArray,
    ClientDesign: convertedClientDesign,
    favorites: trip_favorites || []
  };
  return convertedTripDetails;
}

function getCardBorderColor(type: TRIP_ITEMS, isFree: boolean) {
  return type !== TRIP_ITEMS.Attraction
        ? CardBorderColors.Other
        : isFree
          ? CardBorderColors.FreeAttraction
          : CardBorderColors.PaidAttraction;
}

export function convertAttraction(attractionItem: IItineraryItemAttraction): ITripItem_Attraction {
  const { booked_status, event_details } = attractionItem;

  const eventDetails: EventDetails_Attraction = event_details[0];
  const {
    id,
    location_lat,
    location_lng,
    address,
    number_of_reviews,
    source,
    rating,
    name,
    about,
    tags,
    main_photo_url,
    order_page,
    is_free,
    external_id,
    order_uuid,
  } = eventDetails;

  return {
    _id: attractionItem.id,
    _eventID: id,
    source,
    location: {
      lat: location_lat,
      lng: location_lng,
    },
    address,
    rank: {
      count: +number_of_reviews,
      value: +rating,
    },
    name,
    about,
    tags,
    imgURL: LOAD_IMAGES ? main_photo_url : '',
    orderPage: order_page,
    type: TRIP_ITEMS.Attraction,
    isFree: is_free,
    uuid: external_id || undefined,
    bookingStatus: booked_status,
    orderUuid: order_uuid,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Attraction, is_free),
  };
}
export function convertAttractionEventDetails(
  eventDetails: EventDetails_Attraction,
): ITripItem_Attraction {
  const {
    id,
    location_lat,
    location_lng,
    address,
    number_of_reviews,
    rating,
    name,
    about,
    tags,
    main_photo_url,
    order_page,
    is_free,
    external_id,
    pickup,
    activity_datetime,
    source,
  } = eventDetails;
  return {
    _id: id,
    _eventID: id,
    source,
    location: {
      lat: location_lat,
      lng: location_lng,
    },
    address: address,
    rank: {
      count: +number_of_reviews,
      value: +rating,
    },
    name: name,
    about: about,
    tags: tags,
    imgURL: LOAD_IMAGES ? main_photo_url : '',
    orderPage: order_page,
    type: TRIP_ITEMS.Attraction,
    isFree: is_free,
    uuid: external_id,
    pickup,
    activityDatetime: activity_datetime,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Attraction, is_free),
    // activityDatetime: activity_datetime || "2021-07-31 09:00"
  };
}

function convertResturant(resturantItem: IItineraryItemResturant): ITripItem_Resturant {
  const eventDetails: EventDetails_Resturant = resturantItem.event_details![0];
  return {
    _id: resturantItem.id,
    location: {
      lat: eventDetails.location_lat,
      lng: eventDetails.location_lng,
    },
    address: eventDetails.address,
    rank: {
      count: +eventDetails.number_of_reviews,
      value: +eventDetails.rating,
    },
    name: eventDetails.name,
    about: eventDetails.about,
    tags: eventDetails.tags,
    imgURL: LOAD_IMAGES ? eventDetails.main_photo_url : '',
    orderPage: eventDetails.order_page,
    type: TRIP_ITEMS.Restaurant,
    cardBorderColor: getCardBorderColor(TRIP_ITEMS.Restaurant, false)
  };
}

export function convertCommute(commuteItem: IItineraryItemCommute): ITripItem_Commute {
  const { duration_desc, id } = commuteItem;
  const commuteTime: ITripItem_Commute['commuteTime'] = {};
  // commute = [{commuteType: "driving", content: "20min"} , {commuteType: "walking", content: "10min"}];
  duration_desc.split(',').forEach((commute: string) => {
    const [commuteType, time] = commute.split(':');
    commuteTime[commuteType] = time.replace('mins', 'min.');
  });
  //No walk more than a hourin total
  if (commuteTime.Walking && commuteTime.Walking.includes('hour')) {
    commuteTime.Walking = undefined;
  }
  return {
    _id: id,
    type: TRIP_ITEMS.Commute,
    commuteTime,
  };
}

export function convertClientDesign(response: IClientDesignResponse): IClientDesign {
  const {
    hotels_list,
    city_list,
    hotel_selection,
    date_selection,
    city_selection,
    tokenless_onboarding,
    merchant_flow,
    name,
    PrimaryColor_bg,
    PrimaryColor_text,
    Logo,
    SecondaryColor_bg,
    SecondaryColor_text,
    TertiaryColor_text,
    TertiaryColor_bg,
    irrelevant_interests,
  } = response;
  return {
    name,
    Logo,
    //sort Hotels so coming_soon properties will be first
    hotelList: hotels_list
      ? hotels_list.sort((a, b) => (a.coming_soon === b.coming_soon ? 0 : b.coming_soon ? -1 : 1))
      : undefined,
    cityList: city_list ? city_list : undefined,
    TOKENLESS_ONBOARDING: tokenless_onboarding === true,
    CITY_SELECTION: city_selection === true,
    HOTEL_SELECTION: hotel_selection === true,
    DATE_SELECTION: date_selection === true,
    MERCHANT_FLOW: merchant_flow === true,
    irrelevantInterests: irrelevant_interests ? irrelevant_interests : undefined,
    PrimaryColor: {
      bgColor: PrimaryColor_bg,
      textColor: PrimaryColor_text,
    },
    SecondaryColor: {
      bgColor: SecondaryColor_bg,
      textColor: SecondaryColor_text,
    },
    TertiaryColor: {
      textColor: TertiaryColor_text,
      bgColor: TertiaryColor_bg,
    },
    Images: {
      Image1: {
        imgURL: response.Image1_URL,
        text: response.Image1_text,
      },
      Image2: {
        imgURL: response.Image2_URL,
        text: response.Image2_text,
      },
      Image3: {
        imgURL: response.Image3_URL,
        text: response.Image3_text,
      },
      Image4: {
        imgURL: response.Image4_URL,
        text: response.Image4_text,
      },
      Image5: {
        imgURL: response.Image5_URL,
        text: response.Image5_text,
      },
      Image6: {
        imgURL: response.Image6_URL,
        text: response.Image6_text,
      },
    },
  };
}

export function convertMusementActivity({
  about_html,
  features,
  free_cancellation,
  highlights,
  included,
  not_included,
  info,
  where_text,
  when_text,
  languages,
  meeting_point_html,
  reduced,
  voucher_access_usage,
  latitude,
  longitude,
  title,
  cover_image_url,
  operational_days,
  order_box_elements,
  uuid,
  max_confirmation_time,
  booking_type,
  service_fee,
}: IActivity): IActivity_Converted {
  const langsList: string[] = languages.map((lang) => lang.name);
  const openDateActivity: boolean = booking_type === 'NO-CALENDAR-FIXED-END';
  const maxConfirmationTime: number = +max_confirmation_time[1];
  //activity requires pending if is NaN of if there's confirmation time
  const requiresConfirmation: boolean = isNaN(maxConfirmationTime) || maxConfirmationTime > 0;
  const mandatoryPickupService = order_box_elements.some((item) => item.includes('pickup'));
  const bookingFee: boolean = service_fee?.value > 0 || false;
  return {
    uuid,
    WhatToExpect_HTML: about_html,
    features,
    free_cancellation,
    DoThisBecause: highlights || null,
    whatsIncluded: included || null,
    whatsNotIncluded: not_included || null,
    WhatToRemember_HTML: info,
    where_HTML: where_text,
    when_HTML: when_text,
    languages: langsList,
    meetingPoint: meeting_point_html,
    reducedPrice_HTML: reduced,
    voucher_access_usage,
    title,
    availability: operational_days,
    cover_image_url,
    location: { lat: latitude, lng: longitude },
    openDateActivity,
    mandatoryPickupService,
    requiresConfirmation,
    bookingType: booking_type,
    bookingFee,
  };
}
