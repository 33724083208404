import { forwardRef, useEffect, useState } from "react";
import { interestsDic } from "./../../core/utils/dictionaries";
import { INTERESTS } from "./../../core/utils/enums";
import Item from "./Item/Item";
import { useTripDetails } from "./../../providers/TripDetailsProvider";
import styled from "styled-components/macro";
import { AppBar, Dialog, Skeleton, Slide, Toolbar, useMediaQuery, useTheme } from "@material-ui/core";
import { useFeed } from "./Feed/useFeed";
import { TransitionProps } from "@material-ui/core/transitions";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { clearedHash } from "../../core/utils/common";
import { useHistory } from "react-router-dom";
import StorageService from "../../core/services/storage";
import { getExploreAttractions } from "../../core/services/trip";
import { IExploreAttractions } from "../../core/@types/feed";
import { ErrorAlert } from "../shared/Alerts";
import { Icons } from "../../assets/images/images";



const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function Explore() {
    const history = useHistory()
    const { tripDetails } = useTripDetails()
    const { itemsIDs, tripPreferences: { interests } } = tripDetails
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { state: { showExploreDialog }, dispatch } = useFeed()
    const [loading, setLoading] = useState<boolean>(true)
    // const { exploreAttractions } = useExploreAttractions()
    const [showFavorites, setShowFavorites] = useState<boolean>(false)

    const [selectedInterest, setSelectedInterest] = useState<number>(interests[0]);

    // const exploreAttractionsLength = Object.keys(exploreAttractions).length
    const [exploreAttractions, setExploreAttractions] = useState<IExploreAttractions>({});

    useEffect(() => {

        function setExploreAttractionsWrapper(updatedExploreAttraction: any) {
            if (interests.length > 0) {
                setExploreAttractions(updatedExploreAttraction);
                StorageService.session.save.exploreAttractions(updatedExploreAttraction);
            }
        }

        async function initExploreAttractions() {
            try {
                const cachedExploreAttractions = StorageService.session.get.exploreAttractions();
                if (cachedExploreAttractions) {
                    setExploreAttractions(cachedExploreAttractions);
                } else {
                    const attractions = await getExploreAttractions(interests)
                    setExploreAttractionsWrapper(attractions)
                }
            } catch (error) {

            } finally {
                setLoading(false)

            }

        }
        initExploreAttractions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // useEffect(() => {
    //     scrollWindowToTop()
    //     if (exploreAttractionsLength > 0) {
    //         setSelectedInterest(DisplayedInterests[0])
    //         setLoading(false)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [exploreAttractionsLength])

    useEffect(() => {
        const onHashChange = () => {
            if (clearedHash()) {
                dispatch({ type: 'TOGGLE_SHOW_EXPLORE' })
            }
        }
        window.addEventListener("hashchange", onHashChange);
        return () => window.removeEventListener("hashchange", onHashChange);
    }, []);

    // const handleMoreInterest = useCallback(() => {
    //     // filtering interest for which we already have result
    //     const interestAlreadyPresent = displayedInterests.reduce((prev: any, current: any) => {
    //         prev.push(current.id)
    //         return prev;
    //     }, []);
    //     // filtering next five interest whose results to fetched
    //     //DUE TO COVID hide with filter from users -->sports events(10), shows(15), music(26), local(21), festivals (16), LGBT (25)
    //     const interest = AllInterests?.filter((item: any) => !interestAlreadyPresent.includes(item.id) && ![10, 15, 16, 21, 25, 26].includes(item.id)).slice(0, 5)
    //     if (interest.length > 0) {
    //         const displayedInterest = [...displayedInterests, ...interest];
    //         const tripPrefrences = StorageService.session.get.item('tripPrefrences');
    //         tripPrefrences['interests'] = displayedInterest.map(item => item.id);
    //         StorageService.session.save.item('tripPrefrences', tripPrefrences);
    //         setDisplayedInterests(displayedInterest);
    //         const fetchMoreAttractions = fetchMoreAttractionPerInterest(interest.map((item: any) => item.id));
    //     }
    // }, [AllInterests, displayedInterests]);

    function handleBack() {
        dispatch({ type: 'TOGGLE_SHOW_EXPLORE' });
        history.goBack();
    }

    function isAttractionAlreadyInTrip(attractionID: number) {
        return itemsIDs.includes(attractionID)
    }
    // const DisplayedInterests = interests?.filter(interest => exploreAttractions[interest]?.length > 0)

    function handleInterestChange(index: number) {
        setSelectedInterest(interests[index])
        // setSelectedInterest(DisplayedInterests[index])
    }
    function handleToggleShowFavorites(): void {
        setShowFavorites(prevState => !prevState)
    }

    // const currentDay = StorageService.session.get.currentDay()
    // let currentDayLabel = moment(start_date).add(currentDay, 'days').format('MMM DD')
    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth="xl"
            open={showExploreDialog}
            onBackdropClick={handleBack}
            TransitionComponent={Transition}>
            <StyledAppBar>
                <StyledToolbar  >
                    <TitleAndBackBtn >
                        <StyledBackIcon onClick={handleBack} />
                        <div>Explore</div>
                    </TitleAndBackBtn>
                    <FavoritesButton onClick={handleToggleShowFavorites} >
                        {showFavorites && <WishlistLabel>See All Activities</WishlistLabel>}
                        {!showFavorites && <>
                            <WishlistLabel>My Favorites</WishlistLabel>
                            <img src={Icons.heart_filled} alt="favorites" />
                        </>}
                    </FavoritesButton>
                </StyledToolbar>
            </StyledAppBar>
            <Container>
                {loading ?
                    <div >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Skeleton variant="rectangular" height={30} width={100} animation="wave" style={{ borderRadius: '10px', marginRight: '5px' }} />
                            <Skeleton variant="rectangular" height={30} width={100} animation="wave" style={{ borderRadius: '10px', marginRight: '5px' }} />
                            <Skeleton variant="rectangular" height={30} width={100} animation="wave" style={{ borderRadius: '10px', marginRight: '5px' }} />
                        </div>
                        <SkeletonCardsContainer >

                            <br />
                            <Skeleton variant="rectangular" height={300} width={300} animation="wave" style={{ borderRadius: '10px' }} />
                            <br />
                            <Skeleton variant="rectangular" height={300} width={300} animation="wave" style={{ borderRadius: '10px' }} />
                            <br />
                            <Skeleton variant="rectangular" height={300} width={300} animation="wave" style={{ borderRadius: '10px' }} />
                        </SkeletonCardsContainer>
                        {/* <CircularProgress /> */}
                    </div>
                    : <>
                        {interests
                            ?.filter(interest => exploreAttractions[interest]?.length > 0)
                            ?.map((interest: INTERESTS, index: number) => {
                                return (
                                    <InterestsSliderContainer key={`interest${index}`}>
                                        <InterestTitle>{interestsDic[interest]}</InterestTitle>
                                        <ItemsSlider>
                                            {exploreAttractions[interest]
                                                ?.filter(
                                                    (item) =>
                                                        !showFavorites ||
                                                        StorageService.session.get
                                                            .favorites()
                                                            ?.includes(item._id),
                                                )
                                                ?.filter((item) => !isAttractionAlreadyInTrip(item._id))
                                                .map((item: ITripItem_Attraction, index: number) => {
                                                    const { _id } = item;
                                                    return (
                                                        <Item
                                                            item={item}
                                                            key={`atr${_id}`}
                                                            componentLocation={'Explore'}
                                                            isAttractionAlreadyInTrip={isAttractionAlreadyInTrip(
                                                                _id,
                                                            )}
                                                        />
                                                    );
                                                })}
                                        </ItemsSlider>
                                    </InterestsSliderContainer>
                                );
                            })}
                        {/* <InterestsTitlesContainer >
                            {interests
                                // ?.filter(interest => exploreAttractions[interest]?.length > 0)
                                ?.map((interest: INTERESTS, index: number) =>
                                    <InterestTitle
                                        key={`int${index}`}
                                        onClick={() => handleInterestChange(index)}
                                        selected={selectedInterest === interest}>
                                        {interestsDic[interest]}
                                    </InterestTitle>)}
                        </InterestsTitlesContainer> */}

                        {/* {exploreAttractions &&
                            <ItemsContainer>
                            {items
                                ?.filter(item => !showFavorites || StorageService.session.get.favorites()?.includes(item._id))
                                .map((item: ITripItem_Attraction, index: number) => {
                                    const { _id } = item
                                    return <Item
                                        item={item}
                                        key={`atr${_id}`}
                                        componentLocation={"Explore"}
                                        isAttractionAlreadyInTrip={isAttractionAlreadyInTrip(_id)} />
                                })}
                            {(!items || items.length === 0) &&
                                <ErrorAlert style={{ margin: '1rem' }}>
                                    OOPS! We can't find more attractions from this type, please try again later.
                                </ErrorAlert>}
                            </ItemsContainer>} */}
                    </>}
            </Container>
            {/* </Page > */}
        </Dialog>
    );
};
const ItemsSlider = styled.div`
    display: flex;
    flex-direction: row;
    width:100%;
    overflow-x: scroll;
`;

const InterestsSliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left:1rem;
`;
const TitleAndBackBtn = styled.div`
  display: flex;
  flex-direction: row;
`;
const FavoritesButton = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const StyledToolbar = styled(Toolbar)`
&&{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
`;
const WishlistLabel = styled.div`
    font-size:0.8rem;
    margin-right:5px;
`;
const SkeletonCardsContainer = styled.div`
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledAppBar = styled(AppBar)`
&&{
  background-color: ${p => p.theme.primary.bgColor};
  color: ${p => p.theme.primary.textColor};
  font-size: 1.2rem;
  text-align: center;
  position:sticky;
}
`
const Container = styled.div`
    padding:0.5rem;
    overflow:scroll;
    box-sizing:border-box;
    min-height:80vh;
    min-width:300px;
    width:100%;
    align-items:center;
`;
// const InterestsTitlesContainer = styled.div`
//     overflow-x: scroll;
//     display: -webkit-box;
// `;

const InterestTitle = styled.h3`
  margin-left:1rem;
`;
// const InterestTitle = styled('h3') <{ selected: boolean }>`
/* width: max-content;
padding: 0.5rem 1rem;
margin-right: 0.5rem;
border-radius: 7px;
background-color: ${p => p.selected ? p.theme.primary.bgColor : 'white'};
border: ${p => p.selected ? '' : '1px solid black'} ;
color: ${p => p.selected ? p.theme.primary.textColor : '#383a3d'} ;
cursor: pointer; */
// `;
// const ItemsContainer = styled('div') <{}>`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;


// const StyledSkeleton = styled(Skeleton)`
// &&{
//   width:60%;
//   height:100px;
//   margin-bottom: 1rem;
// }
// `;
const StyledBackIcon = styled(BackIcon)`
&&{
  color:white;
  cursor: pointer;
}
`;

// const ErrorMessage = styled.div`
//   color:#FE4042;
// `
// const Title = styled.div`
//   font-weight: 700;
//   font-size: 1.3rem;
//   margin-bottom:0.3rem;
//   text-transform: capitalize;
// `