import { Langs, LoaderTypes } from '../../../core/utils/enums';
import Interest from './Interest';
import { InterestsLangDic } from '../../../core/utils/dictionaries';
import styled from 'styled-components/macro';
import { IOnBoardingState, TOnboardingActions } from './useOnboarding';
import { useTripDetails } from '../../../providers/TripDetailsProvider';

type PropsI = {
  backButtonHandler: any;
  state: IOnBoardingState;
  nextButtonHandler: any;
  canContinue: boolean;
  loaderType: LoaderTypes;
  dispatch: React.Dispatch<TOnboardingActions>;
};

export default function InterestsPage({
  state: { selected_interests, selectedHotel },
  dispatch,
}: PropsI) {
  const {
    tripDetails: {
      ClientDesign: { irrelevantInterests: ClientIrrelevantInterests },
    },
  } = useTripDetails();

  let interests: { [key: number]: TTripInterest } = InterestsLangDic[Langs.english];
  //DUE TO COVID hide with filter from users -->sports events(10), shows(15), music(26), local(21), festivals (16), LGBT (25)

  //filter irrelevant interests of the client
  if (ClientIrrelevantInterests) {
    ClientIrrelevantInterests?.split(',').forEach((key) => {
      delete interests[key];
    });
  }

  //filter irrelevant interests of the specific property
  if (selectedHotel && selectedHotel.irrelevant_interests) {
    const { irrelevant_interests: propertyIrrelevantInterests } = selectedHotel;
    propertyIrrelevantInterests.split(',').forEach((key) => {
      delete interests[key];
    });
  }

  function handleSelectInterest(id: number) {
    dispatch({ type: 'INTEREST', payload: id });
  }

  return (
    <Container>
      <Title> Please select between 5 to 10 of your favorite categories</Title>
      <InterestsContainer>
        {Object.keys(interests).map((key: string) => {
          const Ckey = +key;
          const { title, img } = interests[key];
          return (
            <Interest
              id={Ckey}
              img={img}
              onClick={() => handleSelectInterest(Ckey)}
              selected={selected_interests}
              title={title}
              key={`${key}${title}`}
            />
          );
        })}
      </InterestsContainer>
    </Container>
  );
}

const Container = styled.div`
  text-align: left;
  max-width: 800px;
  width: 100%;
  margin: auto;
`;

const Title = styled.div`
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;
const InterestsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 120px;
`;
