import { useState } from 'react';
import { IItemButtonsDic } from './Item';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Fab,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Rank from '../../shared/Rank';
import { Icons } from '../../../assets/images/images';
import { BookedStatuses, CardBorderColors } from '../../../core/utils/enums';
import ContomizedButton from '../../shared/Button';
import styled from 'styled-components/macro';
import Modal from '../../shared/Modal';
import CancellationModal from './CancellationModal';
import StorageService from '../../../core/services/storage';
import { addItemFavorites, removeItemFavorites } from '../../../core/services/favorites';
import { useTripDetails } from '../../../providers/TripDetailsProvider';

interface IProps {
  item: ITripItem_Resturant | ITripItem_Attraction;
  componentLocation?: 'Feed' | 'Explore';
  onDelete(id: number | null): void;
  ButtonProps: IItemButtonsDic;
  handleOpenItemDialog: () => void;
  showSpinner: boolean;
  last?: boolean;
  TIMELINE?: boolean;
  ACTIVITIES_COUNTER?: number;
}
export default function ItemCard({
  item,
  componentLocation = 'Feed',
  ACTIVITIES_COUNTER,
  onDelete,
  ButtonProps,
  showSpinner,
  last,
  handleOpenItemDialog,
  TIMELINE = false,
}: IProps) {
  const { imgURL, name, _id: item_id, rank, tags } = item;
  //@ts-ignore
  const bookingStatus: BookedStatuses = item?.bookingStatus || BookedStatuses.NotBooked;
  //@ts-ignore
  // const activityDatetime: string | null = (item?.activityDatetime) ? item.activityDatetime : null
  const {
    tripDetails: { _id: trip_id },
  } = useTripDetails();
  const [showDeletionModal, setShowDeletionModal] = useState<boolean>(false);
  const [showCancelBookingModal, setShowCancelBookingModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const [isFavorite, setIsFavorite] = useState<boolean>(
    StorageService.session.get.favorites()?.includes(item_id) === true,
  );

  const {
    tripDetails: {
      ClientDesign: { PrimaryColor, SecondaryColor },
    },
  } = useTripDetails();

  const cardBorderColor =
    item.cardBorderColor === CardBorderColors.PaidAttraction
      ? PrimaryColor.bgColor
      : item.cardBorderColor === CardBorderColors.FreeAttraction
      ? SecondaryColor.bgColor
      : '#9E9E9E';

  async function confirmDelete(event) {
    event.stopPropagation();
    setSuccess(false);
    setLoading(true);

    setShowDeletionModal(false);
    await onDelete(item_id);

    setSuccess(true);
    setLoading(false);
  }

  function handleDelete(event) {
    event.stopPropagation();
    setShowDeletionModal(true);
  }

  function handleClose(event) {
    event.stopPropagation();
    setShowDeletionModal(false);
  }

  function toggleDeleteIcon(event) {
    event.stopPropagation();
    setShowDeleteIcon(!showDeleteIcon);
  }
  function handleOpenCancelBookingModal(event) {
    event.stopPropagation();
    setShowCancelBookingModal(true);
  }
  function handleCloseCancelBookingModal(event) {
    event.stopPropagation();
    setShowCancelBookingModal(false);
  }

  /**
   * This function does two things simonteniously
   * 1. Adds/Removes the item_id from the favorites on Session Storage
   * 2. Adds/Removes the item_id from the favorites on the backend DB
   * @param event - onClick event
   */
  async function handleToggleSaveToFavorites(event) {
    event.stopPropagation();
    let favorites: Set<number> = new Set(StorageService.session.get.favorites() || []);
    const APICallData = { item_id, trip_id };
    try {
      if (isFavorite) {
        favorites.delete(item_id);
        await removeItemFavorites(APICallData);
      } else {
        favorites.add(item_id);
        await addItemFavorites(APICallData);
      }
    } catch (error) {
      console.log('updatefavorites', error);
    }
    StorageService.session.save.favorites(Array.from(favorites));
    setIsFavorite((prevState) => !prevState);
  }

  const bookingStatusLabelMap: { [key in BookedStatuses]: { text?: string; color?: string } } = {
    [BookedStatuses.NotBooked]: {},
    [BookedStatuses.Cancelled]: { text: 'CANCELLED', color: '#E45B5B' },
    [BookedStatuses.Pending]: { text: 'PENDING', color: '#EE8E1E' },
    [BookedStatuses.Booked]: { text: 'BOOKED', color: '#3DB007' },
  };

  const linkToMusementMail = (
    <b>
      <Alink href="mailto: support@musement.com" target="_top">
        support@musement.com
      </Alink>
    </b>
  );
  const { onClick, text, disabled = false, buttonColor, icon, textColor } = ButtonProps;
  return (
    <CardContainer
      key={item_id}
      onClick={handleOpenItemDialog}
      componentLocation={componentLocation}
    >
      <StyledCard cardBorderColor={cardBorderColor}>
        <CardActionArea>
          {!TIMELINE && <Favorites onClick={handleToggleSaveToFavorites} isfavorite={isFavorite} />}

          {ACTIVITIES_COUNTER && <TimelineIndex>{ACTIVITIES_COUNTER}</TimelineIndex>}
          <CardMedia
            style={{ height: '140px' }}
            image={
              imgURL ||
              'https://bridgify-frontend-s3.s3.eu-central-1.amazonaws.com/image-not-available.png'
            }
          />

          <TagsContainer TIMELINE={TIMELINE}>
            {tags?.slice(0, 2).map((t) => (
              <Tag key={t}>{t}</Tag>
            ))}
          </TagsContainer>
          {componentLocation === 'Feed' && (
            <DeleteIconContainer>
              {bookingStatus === BookedStatuses.Booked ||
              bookingStatus === BookedStatuses.Pending ? (
                <Modal
                  handleBack={handleClose}
                  handleConfirm={confirmDelete}
                  open={showDeletionModal}
                  title={'Are you sure?'}
                >
                  Pending and booked attractions cannot be removed from your trip.
                  <br />
                  If you wish to cancel your order, first cancel it by sending a message to{' '}
                  {linkToMusementMail}
                  <br />
                  <br />
                  Once it's cancelled, you'll be able to remove it from the itinerary.
                </Modal>
              ) : (
                <Modal
                  handleBack={handleClose}
                  handleConfirm={confirmDelete}
                  open={showDeletionModal}
                  title={'Are you sure?'}
                  primaryButtom
                >
                  You are about to remove <b>{name}</b> from your trip itinerary..
                </Modal>
              )}
              {showDeleteIcon && (
                <>
                  <StyledFab color="default" success={success} onClick={handleDelete}>
                    {success ? <CheckIcon /> : <DeleteIcon />}
                  </StyledFab>
                  {loading && <CircularProgress size={37} />}
                </>
              )}
              <ThreeDotsMenu src={Icons.three_dots} alt="item_info" onClick={toggleDeleteIcon} />
            </DeleteIconContainer>
          )}
          <Content>
            <Title>{name}</Title>

            {/* <Typography gutterBottom variant="h6" component="h2">
              {name}
            </Typography> */}
            <RankAndStatusContainer>
              <Rank {...rank} />
              {bookingStatusLabelMap[bookingStatus].text && (
                <>
                  <BookingStatusLabel color={bookingStatusLabelMap[bookingStatus]?.color || ''}>
                    &nbsp; {bookingStatusLabelMap[bookingStatus].text}
                  </BookingStatusLabel>{' '}
                  &nbsp; · &nbsp;
                  {(bookingStatus === BookedStatuses.Booked ||
                    bookingStatus === BookedStatuses.Pending) && (
                    <>
                      <CancelBookingButton onClick={handleOpenCancelBookingModal}>
                        {showCancelBookingModal ? (
                          <CancellationModal
                            handleCloseModal={handleCloseCancelBookingModal}
                            showModal={showCancelBookingModal}
                            //@ts-ignore
                            item={item}
                          />
                        ) : null}
                        Cancel Booking
                      </CancelBookingButton>
                    </>
                  )}
                </>
              )}
            </RankAndStatusContainer>

            {/* <<<<<<<========ABOUT====>>>>>> */}
            {/* {about && <AboutContainer onClick={handleExpandClick} expanded={expanded}>

              <Typography variant="body2" color="textSecondary" component="p">
                {expanded ? about : about.split(' ').slice(0, MAX_ABOUT_WORDS_LENGTH).join(' ') + '...'}
              </Typography><StyledExpandIcon expanded={expanded} />
            </AboutContainer>} */}
          </Content>
        </CardActionArea>
        <ContomizedButton
          text={text}
          background={buttonColor}
          onClick={onClick}
          spinnerState={showSpinner}
          loadingSpinner={true}
          Icon={icon}
          disabled={disabled}
          textColor={textColor}
          style={{ width: '100%', border: 'none', borderTop: '1px solid grey', borderRadius: 0 }}
        />
      </StyledCard>
    </CardContainer>
  );
}
const Favorites = styled.div<{ isfavorite?: boolean }>`
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 1.5rem;
  width: 1.5rem;

  padding: 3px;
  background-color: white;
  border-radius: 2rem;
  background-image: url(${(p) => (p.isfavorite ? Icons.heart_filled : Icons.heart_outlined)});
  background-repeat: no-repeat;
  background-position: center;
`;

const TimelineIndex = styled.div`
  background-color: ${(p) => p.theme.primary.bgColor};
  color: ${(p) => p.theme.primary.textColor};
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  padding: 5px 7px;
  font-weight: 600;
  font-family: Roboto;
  border-radius: 5px;
`;

const CardContainer = styled('div')<{ componentLocation: 'Feed' | 'Explore' }>`
  margin: ${(p) => (p.componentLocation === 'Feed' ? '1rem 0' : '0 1rem')};
  position: relative;
`;

const StyledCard = styled(Card)<{ cardBorderColor: string }>`
  && {
    width: 320px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(63, 63, 63, 0.08), 0px 3px 5px rgba(63, 63, 63, 0.02),
      0px 1px 1px rgba(63, 63, 63, 0.05);
    border: 0.5px solid ${(p) => p.cardBorderColor};
  }
`;

const CancelBookingButton = styled.div`
  color: #535353;
  text-decoration-line: underline;
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

const RankAndStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

const BookingStatusLabel = styled('span')<{ color: string }>`
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: 0.9rem;
`;

const Content = styled(CardContent)`
  && {
    padding-left: 2.2rem;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0e1738;
`;

const StyledFab = styled(Fab)<{ success?: boolean }>`
  && {
    height: 35px;
    width: 35px;
    background-color: ${(p) => (p.success ? '#a5d6a7' : '')};
  }
`;

const Alink = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: darkblue;
`;

const TagsContainer = styled.div<{ TIMELINE: boolean }>`
  position: absolute;
  height: 24px;
  left: ${(p) =>
    p.TIMELINE ? '4rem' : '1rem'}; // set margin to be located next to the Feed's timeline
  top: 1rem;
`;
const DeleteIconContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 3rem;
  display: flex;
`;
const Tag = styled.div`
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 2px;
  font-size: 12px;
  width: fit-content;
`;

const ThreeDotsMenu = styled.img`
  margin-left: 1rem;
  display: flex;

  height: 35px;
  width: 20px;
`;
