import { IOnBoardingState, TOnboardingActions } from './useOnboarding';
import styled from 'styled-components/macro';
import TripTypes from '../../../assets/static/tripTypes/tripTypes';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { Button } from '@material-ui/core';

type PropsI = {
  state: IOnBoardingState;
  dispatch: React.Dispatch<TOnboardingActions>;
};
// Trip Planning trip --> Made for dubai!
export default function TripTypesPage({ dispatch, state: { selected_type: selected } }: PropsI) {
  const {
    tripDetails: {
      ClientDesign: {
        PrimaryColor: { bgColor },
      },
    },
  } = useTripDetails();

  const data: TTripType[] = TripTypes.English;

  function handleTripTypeChange(index: number) {
    dispatch({ type: 'TRIP_TYPE', payload: index });
  }
  return (
    <Container>
      <Title> Tell us what's the trip pace you're aiming for..</Title>

      {data.map((paceType, index) => {
        const { description, title } = paceType;
        return (
          <Pace
            key={index}
            bgColor={bgColor}
            onClick={() => handleTripTypeChange(index)}
            selected={selected === index}
          >
            <PaceTitle>{title}</PaceTitle>
            <Description>{description}</Description>
          </Pace>
        );
      })}
    </Container>
  );
}

const PaceTitle = styled.div`
  font-weight: 500;
  line-height: 35px;
`;
const Title = styled.div`
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
`;
const Description = styled.div`
  color: #4d4e72;
  line-height: 17px;
`;

const Pace = styled(Button)<{ selected?: boolean; bgColor: string }>`
  && {
    border: ${(p) => (p.selected ? `2px solid ${p.bgColor}` : '1px solid rgba(14, 23, 56, 0.24)')};
    border-radius: 8px;
    min-height: 90px;
    padding: 15px 20px;
    margin-bottom: 12px;
    background-color: white;
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 100%;
    width: 100%;
    text-align: left;
    text-transform: initial;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 600px;
  width: 100%;
`;
