import styled from 'styled-components';

//Explore Attraction Card, with a converted data
export default function FeedFooter() {
  const PUBLIC_URL = process.env.PUBLIC_URL;

  return (
    <Container>
      <HR />
      <TermsOfUse>
        <Alink target="_blank" rel="noopener noreferrer" href={PUBLIC_URL + '/files/TermsOfUse.pdf'}>
          Terms Of Service
        </Alink>
        &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
        <Alink target="_blank" rel="noopener noreferrer" href={PUBLIC_URL + '/files/PrivacyNotice.pdf'}>
          Privacy Policy
        </Alink>
      </TermsOfUse>
    </Container>
  )
}

const Container = styled.div`
  width:100%;
  font-weight:300;
  color: gray;
`;

const TermsOfUse = styled.div`
  text-align: center;
`;

const Alink = styled.a`
  font-size: 0.8rem;
  text-decoration: none;
  color: gray;
`;

const HR = styled.hr`
  width: 30%;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;
