import error from './error.svg';
import check from './check.svg';
import safeTravel from './safe-travel.svg';
import warning from './warning.svg';

import Single from './tripPlans/Single.svg';
import Family from './tripPlans/Family.svg';
import Group from './tripPlans/Group.svg';
import Couple from './tripPlans/Couple.svg';

import cup from './loader/cup.svg';
import curve from './loader/curve_path_1.svg';
import mapLoader from './loader/map.svg';
import marker from './loader/marker.svg';
import settings from './loader/settings.svg';
import sign from './loader/sign.svg';
import heart_filled from './icons/heart_filled.svg';
import heart_outlined from './icons/heart_outlined.svg';

import addIcon from './icons/add_icon.svg';
import arrow from './icons/arrow.svg';
import cancelations from './icons/cancelations.svg';
import clock from './icons/clock.svg';
import compass_selected from './icons/compass_selected.svg';
import compass from './icons/compass.svg';
import delete_icon from './icons/delete_icon.svg';
import edit_circle from './icons/edit_circle.svg';
import edit_pencil from './icons/edit_pencil.svg';
import heart from './icons/heart.svg';
import hotel from './icons/hotel.svg';
import landmark from './icons/landmark.svg';
import map from './icons/map.svg';
import navigate from './icons/navigate.svg';
import baggage from './baggage.png';
import Notification from './icons/Notification.svg';
import profile from './icons/profile.svg';
import restaurant from './icons/restaurant.svg';
import search from './icons/search.svg';
import starFill from './icons/star-fill.svg';
import suitcase from './icons/suitcase.svg';
import suitcase_selected from './icons/suitcase_selected.svg';
import three_dots from './icons/three_dots.svg';
import walking from './icons/walking.svg';
import weather from './icons/weather.svg';
import car from './icons/car.svg';
import binocular from './icons/binocular.svg';
import pin from './icons/pin.svg';
import searchIcon from './icons/SearchIcon.png'

import circle_close from './attraction-details/circle_close.svg';
import circle_like from './attraction-details/circle_like.svg';
import circle_upload from './attraction-details/circle_upload.svg';

import WomanPhotographer from './tokenless/WomanPhotographer.png';
import KidPhotographer from './tokenless/KidPhotographer.png';

export const Tokenless = { WomanPhotographer, KidPhotographer };

export const Images = { error, check, safeTravel, warning, baggage };

export const TripPlans = { Couple, Single, Family, Group };

export const Loader = { cup, curve, mapLoader, marker, settings, sign };

export const attractionDetails = { circle_close, circle_like, circle_upload };

export const Icons = {
  heart_filled,
  heart_outlined,
  binocular,
  pin,
  arrow,
  cancelations,
  car,
  clock,
  compass_selected,
  compass,
  delete_icon,
  edit_circle,
  edit_pencil,
  heart,
  hotel,
  landmark,
  map,
  navigate,
  Notification,
  profile,
  restaurant,
  search,
  starFill,
  suitcase,
  suitcase_selected,
  three_dots,
  walking,
  weather,
  addIcon,
  searchIcon,
};

const assets = {
  Tokenless,
  Images,
  Loader,
  Icons,
  TripPlans,
  attractionDetails,
};

export default assets;
