import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

interface IProps {
    location: ILocation,
    google: any
}

const MapContainer = ({ location, google }: IProps) => {
    return (
        <Map google={google}
            initialCenter={location}
            style={{
                border: '1px solid rgba(14, 23, 56, 0.15)',
                boxSizing: 'border-box',
                borderRadius: '12px',
                height: '100%',
            }}>
            <Marker mapCenter={location} />
        </Map>
    );
}
//@ts-ignore
export default GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_API_KEY })(MapContainer);