import Page from '../shared/Page';
import { LoaderTypes } from '../../core/utils/enums';
import VerificationDialog from './Feed/VerificationDialog';
import styled from 'styled-components/macro';
import Map from '../shared/Map/Map';
import { FeedContext, FeedProvider } from './Feed/useFeed';
import { Button } from '@material-ui/core';
import { ShowOnDesktop } from '../shared/Layout';
import Explore from './Explore';
import Timeline from '../shared/Timeline';
import AttractionsPreviewSlider from '../shared/Map/AttractionsPreviewSlider';
import IFrameBookingDialog from './Booking/IFrameBookingDialog';
import { useTripDetails } from '../../providers/TripDetailsProvider';
import BookingFlowComponent from '@bridgify-travel/booking-flow-component';
// import BookingFlowDialog from './Booking/BookingInfo/BookingFlow';

export default function Feed() {
  const {
    dispatch,
    state,
    state: { selectedItemBook, showExploreDialog, openEmailDialog, showBookingFlowDialog },
    actionIcons,
    onAttractionDelete,
  } = FeedProvider();

  const {
    tripDetails: { _id },
  } = useTripDetails();
  const IS_LOADING = _id === 0;

  function handleClose() {
    window.history.back();
    dispatch({ type: 'SELECTED_ITEM_TICKETS', payload: undefined });
    dispatch({ type: 'TOGGLE_BOOKING_FLOW_DIALOG' });
    dispatch({ type: 'SELECTED_ITEM_BOOK', payload: undefined });
  }

  return (
    <Page
      loading={IS_LOADING}
      loaderType={LoaderTypes.WelcomePage}
      // appbar={{
      //     actionIcons,
      //     title: 'Your Trip',
      //     subtitle: `${city} • ${currentDayLabel} `,
      // }}
      footer={{ position: 'relative' }}
      style={{ padding: 0 }}
    >
      <FeedContext.Provider value={{ state, dispatch }}>
        <ShowOnDesktop>
          <MapSideButtons>
            {actionIcons.map(({ icon, onIconClick, text }: IActionIcons, i) => (
              <MapSideButton variant="contained" onClick={onIconClick} key={`actionTopBarFeed${i}`}>
                <Img src={icon} alt="action" />
                <ButtonTitle>{text}</ButtonTitle>
              </MapSideButton>
            ))}
          </MapSideButtons>
        </ShowOnDesktop>
        <Container>
          <Timeline actionIcons={actionIcons} onAttractionDelete={onAttractionDelete} />
        </Container>

        <MapContainer>
          <Map />
        </MapContainer>

        <AttractionsPreviewSlider />
        {/* Only if it's a Tiqets attraction */}
        {(selectedItemBook?.source === 'Tiqets' || selectedItemBook?.source === 'TicketMaster') &&
          selectedItemBook?.uuid && <IFrameBookingDialog />}
        {openEmailDialog && <VerificationDialog />}
        {/* {showBookingFlowDialog && selectedItemBook && <BookingFlowDialog />} */}

        {showBookingFlowDialog && selectedItemBook?.uuid && (
          <BookingFlowComponent
            activityUuid={selectedItemBook.uuid}
            onClickCloseButton={handleClose}
            open={showBookingFlowDialog}
          />
        )}
        {showExploreDialog && <Explore />}
      </FeedContext.Provider>
    </Page>
  );
}

const Img = styled.img`
  height: 40px;
  width: 40px;
`;

const ButtonTitle = styled.span`
  font-size: 12px;
`;

const MapSideButton = styled(Button)`
  && {
    background: #fcfcfc;
    color: black;
    border-radius: 10px 0px 0px 10px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    text-transform: unset;
    padding: 3px 18px 3px 28px;
  }
`;

const MapSideButtons = styled.div`
  position: absolute;
  right: 0;
  top: 30%;
  z-index: 3;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  position: absolute;
  height: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  box-sizing: border-box;
`;
