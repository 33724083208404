import Calls from './calls';
import { Endpoints } from '../utils/endpoints';
import { convertAttractionEventDetails } from './converts';
import { IExploreAttractions } from '../@types/feed';

const {
  bridgify: { get, post },
} = Calls;
//Defined as a POST request because of its payload
export const createTrip = async (payload, tokenless: boolean) =>
  post(Endpoints.createTrip, payload, tokenless);

export const deleteEvent = async (payload: any) => post(Endpoints.deleteEvent, payload);

export const getDayItinerary = async (path: string) => get(path);

export const getInterestById = async (path: string) => get(path);

export const addAttractionToDayItinerary = async (path: string, payload: any) => post(path, payload);

export const getTripDetails = async (): Promise<ITripDetails_Response> => get(Endpoints.tripDetails);


export const getClientDesign = async (name: string, tokenless?: boolean) =>
  post(Endpoints.clientDesign, { name }, tokenless);

// export const getAttraction = (attractionId: number) =>
//   get(Endpoints.attractionInfo, { id: attractionId });

// export const getRestaurant = (restaurantId: number) =>
//   get(Endpoints.restaurantInfo, { id: restaurantId });

interface IAllSetteled {
  status: 'fulfilled' | 'rejected';
  value: any[];
}

export async function getExploreAttractions(interests: number[]) {
  // ): { [index: number]: Array<EventDetails_Attraction> } {
  const requests = interests.map((interest: number) =>
    getInterestById(Endpoints.moreAttractions + interest),
  );
  let mappedAttractions: IExploreAttractions = {};
  try {
    const response: any = await Promise.allSettled(requests);
    for (let i = 0; i < response.length; i++) {
      //Make sure there's no crash in case of failure of a single Promise
      //The mapped attractions who successed will be stored
      try {
        const promiseSettledResponse: IAllSetteled = response[i];
        if (promiseSettledResponse.status === 'fulfilled') {
          const promiseValue: EventDetails_Attraction[] = promiseSettledResponse.value;
          if (!promiseValue || promiseValue.length < 1) continue;

          const interestAttractions = mapAttractionOfInterestsToArray(promiseValue);

          if (!interestAttractions) continue;
          mappedAttractions = {
            ...mappedAttractions,
            [interests[i]]: interestAttractions,
          };
        }
      } catch (error) {
        continue;
      }
    }
  } catch (error) {
    // console.error("getAttractionPerInterest", error);
  }

  return mappedAttractions;
}

function mapAttractionOfInterestsToArray(
  attractionsArray: EventDetails_Attraction[],
): ITripItem_Attraction[] {
  const convertedArray: ITripItem_Attraction[] = attractionsArray.map(
    (attraction: EventDetails_Attraction) => {
      const convertedAttraction = convertAttractionEventDetails(attraction);
      return convertedAttraction;
    },
  );
  return convertedArray;
}
