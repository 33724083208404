import Calls from './calls';
import { Endpoints } from '../utils/endpoints';

const { bridgify: { post } } = Calls;

interface IUpdateFavorites {
  trip_id: ITripDetails_Converted["_id"];
  item_id: IEventDetails["id"];
}
export const addItemFavorites = async (data: IUpdateFavorites): Promise<void> => post(Endpoints.addItemFavorites, data);

export const removeItemFavorites = async (data: IUpdateFavorites): Promise<void> => post(Endpoints.removeItemFavorites, data);

interface IGetFavoriteItems {
  trip_id: ITripDetails_Converted["_id"];
}
export const getFavoriteItems = async (data: IGetFavoriteItems): Promise<number[]> => post(Endpoints.getFavoriteItems, data);
