import { Route, Switch } from "react-router-dom";
import Feed from "./components/pages/Feed";
import OnBoarding from "./components/pages/Onboarding/Onboarding";
import WelcomePage from "./components/pages/WelcomePage";

import { Routes } from './core/utils/endpoints'
import Page404 from "./components/pages/Page404";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { TripDetailsProvider } from './providers/TripDetailsProvider';
import { LayoutProvider } from './providers/LayoutProvider';

//@ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_MUSEMENT_STRIPE_TOKEN);

export default function App(): JSX.Element {
  return (
    <Elements stripe={stripePromise}>
      <LayoutProvider>
        <TripDetailsProvider>
          <Switch>
            <Route path={Routes.feed} exact component={Feed} />
            <Route path={Routes.onBoarding} exact component={OnBoarding} />
            <Route path={Routes.default} exact component={WelcomePage} />
            <Route component={Page404} />
          </Switch>
        </TripDetailsProvider>
      </LayoutProvider>
    </Elements>
  );
}