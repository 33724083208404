import { useEffect, createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteEvent } from '../../../core/services/trip';
import { Icons } from '../../../assets/images/images';
import StorageService from '../../../core/services/storage';
import { Routes } from '../../../core/utils/endpoints';
import { HASH, TOAST_TYPES, TRIP_ITEMS } from '../../../core/utils/enums';
import { TripItemsDic } from '../../../core/utils/dictionaries';
import { useToasts } from 'react-toast-notifications';
import Mixpanel, { MP_Events, MP_Pages, MP_Props } from '../../../core/services/mixpanel';
import moment from 'moment';
import { useTripDetails } from '../../../providers/TripDetailsProvider';
import { setHash } from '../../../core/utils/common';

type TFeedActions =
  | 'CURRENT_DAY_INDEX'
  | 'SELECTED_ITEM_BOOK'
  | 'OPEN_MAP_VIEW'
  | 'RESET_BOOKING'
  | 'SELECTED_ITEM_TICKETS'
  | 'SELECTED_ITEM_PAYMENT'
  | 'TOGGLE_BOOKING_FLOW_DIALOG'
  | 'OPEN_EMAIL_DIALOG'
  | 'TOGGLE_SHOW_EXPLORE'
  | 'TOGGLE_TIQETS_DIALOG';

interface IAction {
  type: TFeedActions;
  payload?: any;
}

interface IUseFeed {
  state: IState;
  dispatch: React.Dispatch<IAction>;
}
interface IState {
  currentDayIndex: number;
  selectedItemBook?: ITripItem_Attraction;
  selectedItemTickets?: IActivity_Converted;
  selectedItemPayment?: boolean;
  openEmailDialog: boolean;
  showMapView: boolean;
  showExploreDialog: boolean;
  openTiqetsBookingDialog: boolean;
  showBookingFlowDialog: boolean;
}
const initialState: IState = {
  currentDayIndex: StorageService.session.get.currentDay() || 0,
  openEmailDialog: false,
  showMapView: false,
  showExploreDialog: false,
  openTiqetsBookingDialog: false,
  showBookingFlowDialog: false,
};

export const FeedContext = createContext<IUseFeed>({ state: initialState, dispatch: () => {} });
export const useFeed = () => useContext<IUseFeed>(FeedContext);

export function FeedProvider(): typeof exports {
  const history = useHistory();
  const { addToast } = useToasts();
  const {
    tripDetails: {
      ClientDesign: { name: clientName },
      _id,
      tripAccommodation: { start_date },
      tripDays,
    },
    updateTripDetails,
  } = useTripDetails();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentDayIndex, showMapView } = state;

  function reducer(state: IState, action: IAction): IState {
    const { payload, type } = action;
    switch (type) {
      case 'CURRENT_DAY_INDEX':
        return { ...state, currentDayIndex: payload };
      case 'OPEN_EMAIL_DIALOG':
        return { ...state, openEmailDialog: payload };
      case 'SELECTED_ITEM_BOOK':
        return { ...state, selectedItemBook: payload };
      case 'SELECTED_ITEM_TICKETS':
        return { ...state, selectedItemTickets: payload };
      case 'SELECTED_ITEM_PAYMENT':
        return { ...state, selectedItemPayment: payload };
      case 'OPEN_MAP_VIEW':
        return { ...state, showMapView: payload };
      case 'TOGGLE_SHOW_EXPLORE':
        return { ...state, showExploreDialog: !state.showExploreDialog };
      case 'TOGGLE_TIQETS_DIALOG':
        return { ...state, openTiqetsBookingDialog: !state.openTiqetsBookingDialog };
      case 'TOGGLE_BOOKING_FLOW_DIALOG':
        return { ...state, showBookingFlowDialog: !state.showBookingFlowDialog };
      case 'RESET_BOOKING':
        return {
          ...state,
          selectedItemBook: undefined,
          selectedItemTickets: undefined,
          selectedItemPayment: undefined,
        };
    }
  }

  // useEffect(() => {
  //   async function OpenEmailValidationDialog() {
  //     await delay(2000);
  //     dispatch({ type: 'OPEN_EMAIL_DIALOG', payload: true })
  //     window.removeEventListener('scroll', OpenEmailValidationDialog)
  //   }
  //   async function initEmailVerificationDialog() {
  //     await window.addEventListener('scroll', OpenEmailValidationDialog)
  //   }
  //   if (TOKENLESS_ONBOARDING && !StorageService.local.get.validatedEmail()) {
  //     initEmailVerificationDialog()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  async function onAttractionDelete(index: number) {
    const tripDay = tripDays[currentDayIndex];
    //@ts-ignore
    const attraction: ITripItem_Resturant | ITripItem_Attraction = tripDay.items[index];
    const payload = {
      itinerary_item_id: attraction._id,
      day_itinerary_id: tripDay._id,
    };
    try {
      Mixpanel.track(MP_Events.DeleteItem, {
        Page: MP_Pages.Feed,
        tags: attraction?.tags.join(', '),
        title: attraction?.name,
        [MP_Props.client]: clientName,
      });
      await deleteEvent(payload);
      updateTripDetails();
      addToast('The attraction was deleted', {
        appearance: TOAST_TYPES.Success,
        autoDismiss: true,
      });
    } catch (error) {
      addToast('Oops! We had trouble with deleting the activity', {
        appearance: TOAST_TYPES.Error,
        autoDismiss: true,
      });
    }
  }

  // function turnOffLoader(): void {
  //     dispatch({ type: "TURN_OFF_LOADER" });
  // }

  function navigateToMapView(): void {
    Mixpanel.track(MP_Events.MapIcon, { [MP_Props.client]: clientName });
    history.push(Routes.mapView, { tripDays, currentDayIndex });
  }

  // navigate to explore screen to add itinerary to particular day
  function handleOpenExplore() {
    Mixpanel.track(MP_Events.Explore, { [MP_Props.client]: clientName });
    // const dayData = { index: currentDayIndex, id: tripDays[currentDayIndex]._id }
    setHash(HASH.Explore, '');
    dispatch({ type: 'TOGGLE_SHOW_EXPLORE' });
    // history.push(Routes.explore, { day: dayData });
  }

  //List of Icons placed on the topbar
  const actionIcons: IActionIcons[] = [
    {
      icon: Icons.map,
      onIconClick: () => dispatch({ type: 'OPEN_MAP_VIEW', payload: !showMapView }),
      text: 'Map View',
    },
    {
      icon: Icons.binocular,
      onIconClick: handleOpenExplore,
      text: 'Explore',
    },
  ];

  const RenderComponent = (item: ITripDay['items'][0], index: number) => {
    const key: TRIP_ITEMS = item.type;
    const Component = TripItemsDic[key].component;
    return (
      <Component
        item={item}
        key={item._id}
        onDelete={() => onAttractionDelete(index)}
        last={index === tripDays[currentDayIndex]?.items.length - 1}
      />
    );
  };

  const ItemsList = tripDays[currentDayIndex]?.items.map((dayItinerary, index) =>
    RenderComponent(dayItinerary, index),
  );

  let currentDayLabel = moment(start_date).add(currentDayIndex, 'days').format('MMM DD');

  // function LocationMapper(selectedDayIndex: number): Array<IItemLocation> {
  //     const selectedDay = tripDays[selectedDayIndex]
  //     // const itemsLocations = selectedDay.items
  //     //     .map((item, index: number) => {
  //     //         const { type } = item
  //     //         //@ts-ignore
  //     //         const location: ILocation | null = item.location || null
  //     //         // if (item.type === TRIP_ITEMS.Commute) return null;
  //     //         return location ? { type, index, location } : null
  //     //     })
  //     // //@ts-ignore
  //     // const filteredUndefiend: Array<IItemLocation> = itemsLocations.filter(item => item !== null);
  //     // return filteredUndefiend;
  //     let itemsLocations = []
  //     selectedDay.items.forEach((item, index: number) => {
  //         if (item.type !== TRIP_ITEMS.Commute) {
  //             const { type } = item
  //             const location: ILocation | null = item.location || null
  //             itemsLocations.push([{ type, index, location }])
  //         }
  //     })

  //     //@ts-ignore
  //     const filteredUndefiend: Array<IItemLocation> = itemsLocations.filter(item => item !== null);
  //     return filteredUndefiend;
  // }

  const exports = {
    currentDayLabel,
    ItemsList,
    RenderComponent,
    actionIcons,
    state,
    dispatch,
    navigateToMapView,
    onAttractionDelete,
    reducer,
  };
  return exports;
}
