import { Endpoints } from '../utils/endpoints';
import Calls from './calls';

const {
  bridgify: { post },
} = Calls;
interface ISendEmail {
  email: string;
  trip_id: ITripDetails_Converted['_id'];
  client: IClientDesign["name"];
}
export async function sendVerificationEmail(payload: ISendEmail) {
  return post(Endpoints.sendVerificationEmail, payload);
}

interface ISendCode extends ISendEmail {
  verification_code: string;
}
export async function sendVerificationCode(payload: ISendCode) {
  return post(Endpoints.verifycode, payload);
}

interface ILoginToExistingTrip {
  email: string;
  verification_code: string;
}
export async function OnboardingLoginToExistingTrip(payload: ILoginToExistingTrip): Promise<
  { token: ITripDetails_Response["token"], trip: ITripDetails_Converted["_id"] }[]> {
  // throw new Error("s");
  return post(Endpoints.getUserToken, payload, true);
}
interface IResendCode {
  email: string;
  client: IClientDesign["name"];
}
export async function resendCode(payload: IResendCode): Promise<void> {
  // throw new Error("s");
  return post(Endpoints.getUserTokenWithMail, payload, true);
}