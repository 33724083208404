import { LinearProgress } from '@material-ui/core';
import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Images } from '../../assets/images/images';
import { Routes } from '../../core/utils/endpoints';
import { useTripDetails } from '../../providers/TripDetailsProvider';

export default function Page404() {
  const {
    tripDetails: {
      _id,
      ClientDesign: { Logo: LogoSrc },
    },
    navigatePageWithWelcomeScreen,
  } = useTripDetails();

  useEffect(() => {
    if (_id !== 0) {
      navigatePageWithWelcomeScreen(Routes.feed);
    }
  }, []);
  return (
    <>
      <ProgressLoader />
      <Container>
        <Content>
          <LogoContainer>{<Logo src={Images.baggage} alt="client-logo" />}</LogoContainer>
          <Title>Welcome to your personalized trip itinerary! </Title>
          {/* <SubTitle>TRIP NOT FOUND </SubTitle> */}
          {/* <Description>
        Here's 
      </Description> */}

          {LogoSrc && <Logo src={LogoSrc} alt="client-logo" />}
        </Content>
      </Container>
    </>
  );
}
const ProgressLoader = styled(LinearProgress)`
  && {
    width: 100%;
    background-color: ${(p) => p.theme.primary.bgColor};
    .css-5ir5xx-MuiLinearProgress-bar1,
    .css-1r8wrcl-MuiLinearProgress-bar2 {
      background-color: white;
    }
  }
`;
const Content = styled.div`
  background: #fbfbfb;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
`;
const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
`;
// const Description = styled.div`
//   font-size: 1.2rem;
//   font-weight: 400;
//   color: #0e1738;
//   text-align: center;
//   margin-bottom: 3rem;
//   `;
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #0e1738;
  margin-bottom: 3rem;
`;
// const SubTitle = styled.div`
//   font-size: 1.5rem;
//   font-weight: 400;
//   color: #0e1738;
//   margin-bottom: 3rem;
//   `;
const LogoContainer = styled.div`
  margin-bottom: 3rem;
`;
const Logo = styled.img`
  max-height: 30vh;
  max-width: 80%;
  height: auto;
  width: fit-content;
`;
