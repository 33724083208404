import { useMemo } from "react";
import styled from "styled-components/macro";
import StorageService from "../../../core/services/storage";
import { useTripDetails } from "../../../providers/TripDetailsProvider";
import { useFeed } from "./useFeed";

export default function DaysSlider() {
  const { tripDetails: { tripDays: days } } = useTripDetails()
  const { state: { currentDayIndex }, dispatch } = useFeed()

  //Renders the new day's feed and keeps in storage the last day was viewed
  function onDayClick(selectedDayIndex: number): void {
    if (currentDayIndex !== selectedDayIndex) {
      StorageService.session.save.currentDay(selectedDayIndex);
      dispatch({ type: 'CURRENT_DAY_INDEX', payload: selectedDayIndex })
    }
  }

  const Days = useMemo(() => {
    return days.map(({ _id }, index) => {
      const SELECTED: boolean = (currentDayIndex === index)
      return (
        <Day key={`day${index}`} SELECTED={SELECTED} onClick={() => onDayClick(index)}>
          {`Day ${index + 1}`}
        </Day>
      )
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days.length, currentDayIndex]);

  return (
    <Container>
      {Days}
    </Container>
  );
}

const Container = styled.div`
  overflow-x: scroll;
  width: 100%;
  display: -webkit-box;
  padding: 0 1rem;
`;

const Day = styled('div') <{ SELECTED: boolean }>`
&&{
  background-color: ${p => p.SELECTED ? p.theme.primary.bgColor : '#fffdfdea'};
  color: ${p => p.SELECTED ? p.theme.primary.textColor : 'black'};
  font-weight: 500;
  /* border: 1px solid ${p => p.SELECTED ? p.theme.primary.bgColor : 'black'}; */
  margin-right: 1rem;
  font-size: 0.8rem;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
`;
