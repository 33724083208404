import { Langs } from '../utils/enums';

const params = new URLSearchParams(window.location.search);

const getRedirectionSource = (): string | null => params.get('rd') || null;

const getToken = (): string | null => params.get('rt') || null;
const getTEST = (): boolean => params.get('test') !== null;

//@ts-ignore
const getLanguage = (): Langs => params.get('lng') || null;

const getClientParam = (): string | null => params.get('client');

/**This param identify a specific mode
 * 'reuseable' => will cause a cookies deletion during the tripDetails initialization */
type TModes = 'reuseable';
const getMode = (): TModes | null => {
  switch (params.get('mode')) {
    case 'reuseable':
      return 'reuseable';
  }
  return null
};

export function getClientSubdomain(): string | null {
  let hostname = window.location.hostname;
  const URLArray = hostname.split('.'); //(5) ["renas", "dev", "bridgify", "io"] or ["renas", "bridgify", "io"]
  let ClientName = URLArray[0]; //renas in both cases (prod & dev)
  return ClientName !== 'app' && ClientName !== 'localhost' ? ClientName : null;
}

// changing client based on the hostname
function getClient(): string | null {
  let finalClientName: string | null = null;

  const clientNameFromSubdomain: string | null = getClientSubdomain();
  if (clientNameFromSubdomain) {
    finalClientName = clientNameFromSubdomain;
  } else {
    const clientNameFromURL: string | null = getClientParam();
    if (clientNameFromURL) {
      finalClientName = clientNameFromURL;
    }
  }

  return finalClientName;
  // return process.env.NODE_ENV === "production"
  //   ? finalClientName
  //   : TESTING_CUSTOMER;
}
const ParamsService = {
  getToken,
  getLanguage,
  getClient,
  getRedirectionSource,
  getTEST,
  getMode
};

export default ParamsService;
