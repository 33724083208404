import styled from "styled-components/macro";

interface IProp {
  id: number,
  title: string,
  img: string,
  selected: any,
  onClick: any
}

export default function Interest({
  id, title, img, onClick, selected
}: IProp) {
  return (
    <Container key={id} onClick={onClick} id={`${id}`}>
      <ImgContainer>
        <Image src={img} alt="interest_image" />
        <SelectOverlay selected={selected.has(id)}>
          <Check>
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.6833 7.05832L9.91668 20.825L4.31668 15.225L2.68335 16.9167L9.91668 24.0917L25.3167 8.69165L23.6833 7.05832Z" fill="#43A047" />
            </svg>
          </Check>
        </SelectOverlay>
      </ImgContainer>
      <Title>{title}</Title>
    </Container>)
}


const Container = styled.div`
  width: 45%;
  max-width: 190px;
  min-width: 160px;
  height: 153px;
  background: #ffffff;
  border: 1px solid rgba(14, 23, 56, 0.24);
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 17px;
  cursor:pointer;
`;
const ImgContainer = styled.div`
  width: 100%;
  height: 110px;
  position: relative;
`;
const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
  border-radius: 8px 8px 0px 0px;
`;

const SelectOverlay = styled('div') <{ selected: boolean }>`
  transition: 0.5s ease;
  opacity: ${p => p.selected ? '1' : '0'};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Check = styled.div`
  background-color: #fbfbfb;
  font-size: 16px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

`;
const Title = styled.div`
  font-family: inherit, sans-serif;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  color: #4d4e72;
  padding: 10px;
`;